import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';

interface Timbrador {
  timbrador: string;
}
@Component({
  selector: 'app-ver-timbrador',
  templateUrl: './ver-timbrador.component.html',
  styleUrls: ['./ver-timbrador.component.css']
})
export class VerTimbradorComponent implements OnInit {
  timbradores: Timbrador[];

  constructor(public _tema: TemaService) {
    this.timbradores = [
      { timbrador: 'Timbrador 1' },
      { timbrador: 'Timbrador 2' }
    ];
  }

  ngOnInit() {
  }

}
