import { Routes, RouterModule, CanActivate } from '@angular/router';
/*+++++++++++++++++++++++++++++++++++++++++++*/
/*=== GuardServices ===*/
/*+++++++++++++++++++++++++++++++++++++++++++*/
import { AuthGuardService } from './services/auth-guard.service';
/*+++++++++++++++++++++++++++++++++++++++++++*/
/*=== componentes ===*/
/*+++++++++++++++++++++++++++++++++++++++++++*/
import { HomeComponent } from './components/home/home.component';
import { EmpresasEmisorasComponent } from './components/empresas-emisoras/empresas-emisoras.component';
import { UsersComponent } from './components/users/users.component';
// import { DestinatariosZipComponent } from './components/destinatarios-zip/destinatarios-zip.component';
// import { CertificadosComponent } from './components/certificados/certificados.component';
// import { TimbresFiscalesComponent } from './components/timbres-fiscales/timbres-fiscales.component';
import { ComprobantesComponent } from './components/comprobantes/comprobantes.component';
// import { AuditoriasComponent } from './components/auditorias/auditorias.component';
// import { CargaLayoutComponent } from './components/carga-layout/carga-layout.component';
import { VerEstadisticasComponent } from './components/ver-estadisticas/ver-estadisticas.component';
import { LoginComponent } from './login/login.component';
import { ManagerComponent } from './components/manager.component';
import { ExtrasComponent } from './components/extras/extras.component';
import { PaypalLoginComponent } from './paypal-login/paypal-login.component';
import { PaypalValidationComponent } from './paypal-login/paypal-validation/paypal-validation.component';
import { DownloadFilesComponent } from './paypal-download/download-files/download-files.component';
import { DownloadGuard } from './paypal-download/download.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { OpenConfigComponent } from './components/open-config/open-config.component';

const routes: Routes = [
  { path: '', component: PaypalLoginComponent },
  // { path: 'payPal', component: PaypalLoginComponent },
  { path: 'payPal', component: PaypalValidationComponent },
  { path: 'descargar', component: DownloadFilesComponent, canActivate: [DownloadGuard] },
  { path: 'config', component: OpenConfigComponent },
  {
    path: 'indicador', component: ManagerComponent, canActivateChild: [AuthGuardService], children:
      [
        // { path: 'home', component: HomeComponent },

        // { path: 'empresas_emisoras', component: EmpresasEmisorasComponent },
        { path: 'usuarios', component: UsersComponent },
        { path: 'filtros-extras', component: ExtrasComponent },
        // { path: 'destinatarios_zip', component: DestinatariosZipComponent},
        // { path: 'certificados', component: CertificadosComponent },
        // { path: 'timbres_fiscales', component: TimbresFiscalesComponent },
        { path: 'comprobantes', component: ComprobantesComponent },
        // { path: 'auditorias', component: AuditoriasComponent},
        // { path: 'cargar_layout', component: CargaLayoutComponent},
        // { path: 'ver_estadisticas', component: VerEstadisticasComponent },
        { path: '', redirectTo: 'comprobantes', pathMatch: 'full' },
      ]
  },
  { path: 'notFound', component: PageNotFoundComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'notFound' }

];


export const appRouting = RouterModule.forRoot(routes);
