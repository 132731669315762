import { trigger, state, style, transition, animate } from '@angular/animations';


export const animationPayPal = [
    // animacion de la card principal
    trigger('mainCard', [
        state('in', style({})),
        transition('void => in', [
            style({
                width: '100%',
                height: '100%',
                'border-radius': '0'
            }),
            animate(400, style({
                width: '100%',
                height: '100%',
                'border-radius': '0'
            })),
            animate(500)
        ])
    ]),
    // animaciones de logos
    trigger('logosPaypal', [
        state('out', style({
            display: 'none',
            opacity: '0'
        })),
        state('in', style({
            opacity: '1'
        })),
        transition('out => in', [animate(300)])
    ]),
    // animacion logo detecno
    trigger('logosDetecno', [
        state('out', style({
            top: '-45px',
            display: 'none',
            opacity: '0'
        })),
        state('in', style({
            opacity: '1'
        })),
        transition('out => in', [animate(400)])
    ])

];
