import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Imagen } from '../../interface/interface';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() extencion = 'data';
  @Input() tipoExtencion: string[] = new Array();
  @Output() archivoSel: EventEmitter<any> = new EventEmitter();

  msgError = false;

  inputFile = new FormControl();
  text = new FormControl();
  imagen: Imagen;
  // ima: string | ArrayBuffer = '../../../assets/img/logo.png';
  // ima;

  constructor() {
  }

  ngOnInit() {
  }
  onFileChanged(event) {
    const file = event.target.files[0];

    if (file !== undefined) {


      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();

        reader.onload = (eve: ProgressEvent) => {
          this.imagen = {
            data: file,
            direccion: (<FileReader>eve.target).result
          };

          if (this.extencion === 'img') {
            if (this.filtrarExtencion(file.name, ['.png'])) {
              this.archivoSel.emit(this.imagen);
            } else {
              this.mostrarError();
            }
          } else if (this.tipoExtencion.length > 0) {
            if (this.filtrarExtencion(file.name, this.tipoExtencion)) {
              this.archivoSel.emit(this.imagen.data);
            } else {
              this.mostrarError();
            }
          } else {
            this.archivoSel.emit(this.imagen.data);
          }

        };

        reader.readAsDataURL(event.target.files[0]);
      }



      this.text.setValue(file.name);
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Metosdo para limpira la seleccion ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  limpiar() {
    this.text.setValue('');
    this.inputFile.setValue('');
    this.imagen = {
      data: null,
      direccion: null
    };
    if (this.tipoExtencion.length > 0) {
      this.archivoSel.emit(this.imagen.data);
    } else {
      this.archivoSel.emit(this.imagen);
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Metodo para validar la extencion ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  filtrarExtencion(archivo: string, filtroExt: string[]): boolean {
    let archivoExt = '';
    for (let i = archivo.length - 1; i > -1; i--) {
      archivoExt = archivo[i] + archivoExt;
      if (archivo[i] === '.') {
        for (let x = 0; x < filtroExt.length; x++) {
          if (filtroExt[x] === archivoExt) {
            return true;
          }
        }
        return false;
      }
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Mostrar mensaje de error ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  mostrarError() {
    this.limpiar();
    this.msgError = true;
    setTimeout(() => {
      this.msgError = false;
    }, 2000);
  }

}
