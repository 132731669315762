import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-ver-estadisticas',
  templateUrl: './ver-estadisticas.component.html',
  styleUrls: ['./ver-estadisticas.component.css']
})
export class VerEstadisticasComponent implements OnInit {
  @ViewChild('drawer1') drawer1: MatDrawer;
  // types: {}[];
  // selectedType = 'timbres fiscales';
  selectedType = 0;

  constructor() {
    // this.types = [
    //   { label: 'Timbres Fiscales', value: 'timbres fiscales' },
    //   { label: 'Series y Folios', value: 'series y folios' },
    //   { label: 'Comprobantes', value: 'comprobantes' }
    // ];
  }

  ngOnInit() {
  }
  // ocultarBusqueda() {
  //   if (this.selectedType === 'timbres fiscales') {
  //     this.drawer1.close();
  //   } else {
  //     this.drawer1.open();
  //   }
  // }

  ocultarBusqueda(selccion) {
    this.selectedType = selccion.index;
    if (selccion.index < 1) {
      this.drawer1.close();
    } else {
      this.drawer1.open();
    }
  }

  activarBusqueda() {
    if (this.selectedType > 0) {
      this.drawer1.toggle();
    }
  }
}
