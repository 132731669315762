import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import * as jwt_decode from "jwt-decode";

import { epLogin } from "./services/endpoint";
import { Injectable } from "@angular/core";
import { MessageComponent } from "./modals/message/message.component";
import { MatDialog } from "@angular/material";
import { PermisosService, LoadingService } from "./index.services";
import { UserAuth } from "./services/types/http";

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  loginURL = epLogin.login;
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    private _matDialog: MatDialog,
    private _permisosService: PermisosService,
    private _loadingService: LoadingService /*=====Servicios=====*/
  ) {}

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*===metodo interceptor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqClone = req.clone();
    return next.handle(reqClone).pipe(
      tap(
        (res: HttpResponse<any>) => {
          if (res.type === 3) {
            this._loadingService.eveDescaraga.next(res["loaded"]);
          }
          if (res.type === 4) {
            try {
              let token = res.headers.get("Authorization");
              if (token) {
                this.setUser(token);
              }
            } catch (error) {}
          }
        },
        (err) => {
          if (err.status === 401) {
            if (err.url !== this.loginURL) {
              const modalEmp = this._matDialog.open(MessageComponent, {
                disableClose: true,
              });
            }
          }
        }
      )
    );
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== buscar excepción para paypal ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  isUrlException(url: string) {
    const listException: string[] = [epLogin.login + "/paypal?"];
    for (const item of listException) {
      const temp = url.slice(0, item.length);
      if (temp === item) {
        return true;
      }
    }
    return false;
  }

  private setUser(token: string): void {
    token = token.split(" ")[1];
    const data = jwt_decode(token);
    this._permisosService.setPermisos(data.permissions);
    const user: UserAuth = {
      id: data.userid,
      mail: data.usermail,
      token: token,
      fiscalFields: data.fiscalFields,
      completedAuthorized: data.completedAuthorized,
      notify: data.notify,
    };
    localStorage.setItem("usuario", JSON.stringify(user));
  }
}
