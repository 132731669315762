import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comprobante-resposive',
  templateUrl: './comprobante-resposive.component.html',
  styleUrls: ['./comprobante-resposive.component.css']
})
export class ComprobanteResposiveComponent implements OnInit {
  @Input() infoComprbante = 'error';
  @Input() permiso = false;
  @Output() pdfButon: EventEmitter<boolean> = new EventEmitter();
  @Output() xmlButon: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onPDF(open) {
    this.pdfButon.emit(open);
  }

  onXML(open) {
    this.xmlButon.emit(open);
  }
}
