import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.scss']
})
export class DownloadFilesComponent implements OnInit {
  /* etiquet anchor para descargar docs (para que fucione con firefox) */
  @ViewChild('anchorDownload') anchorDownload: ElementRef;
  downloadEstatus: 'load' | 'ok' | 'bad';

  contador = 3;
  constructor(
    /*=========================Servicios=========================*/
    private _HttpService: HttpService,
    private _Router: Router
    /*=========================Servicios=========================*/
  ) {
    const infoDownload = JSON.parse(localStorage.getItem('downloadData'));
    localStorage.removeItem('downloadData');
    if (infoDownload) {
      if (infoDownload.hasOwnProperty('id') && infoDownload.hasOwnProperty('tipo')) {
        this.downloadEstatus = 'load';
        switch (infoDownload.tipo) {
          case 'pdf':
            this.getPDF(infoDownload.id);
            break;
          case 'xml':
            this.getXML(infoDownload.id);
            break;
          default:
            this.downloadEstatus = 'bad';
            this.onRedirect();
            break;
        }
      } else {
        this.downloadEstatus = 'bad';
        this.onRedirect();
      }
    } else {
      this.downloadEstatus = 'bad';
      this.onRedirect();
    }
  }

  ngOnInit() {
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar un pdf ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getPDF(id) {
    this._HttpService.getPDF(id).subscribe(
      (resp: { data: Blob, extension: string }) => {
        const hoy = new Date();
        const nombre = hoy.getDate() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getFullYear();
        this.getFile(resp.data, 'pdf', resp.extension);
        this.downloadEstatus = 'ok';
        // localStorage.removeItem('downloadData');
        this.onRedirect();
      },
      () => {
        this.downloadEstatus = 'bad';
        this.onRedirect();
      }
    );
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar un xml ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getXML(id) {
    this._HttpService.getXML(id).subscribe(
      (resp: { data: Blob, extension: string }) => {
        const hoy = new Date();
        const nombre = hoy.getDate() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getFullYear();
        this.getFile(resp.data, 'xml', resp.extension);
        this.downloadEstatus = 'ok';
        // localStorage.removeItem('downloadData');
        this.onRedirect();
      },
      () => {
        this.downloadEstatus = 'bad';
        this.onRedirect();
      }
    );
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar/ver el archivo que manda el back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getFile(info, tipo: string, nombre: string) {
    const newBlob = new Blob([info], { type: 'application/' + tipo });
    const data = window.URL.createObjectURL(newBlob);
    this.anchorDownload.nativeElement.href = data;
    this.anchorDownload.nativeElement.download = nombre;
    this.anchorDownload.nativeElement.click();
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para Redirigir al portal ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onRedirect() {
    const timer = setInterval(() => {
      this.contador--;
      if (!this.contador) {
        clearInterval(timer);
        this._Router.navigate(['/indicador/comprobantes']);
      }
    }, 1000);



  }

}
