import { Message } from "primeng/api";

export const editUserNotifications:{ [key: string]: Message } = {
    "ok": {
        detail: "Usuario modificado exitosamente",
        severity: "success"
    },
    "error": {
        detail: "Error al modificar el usuario",
        severity: "error"
    },
    "error_user": {
        detail: "¡Error! Acceso no permitido para configurar el usuario",
        severity: "error"
    }
}