import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Underscore } from "src/app/classes/underscore";
import { CfdiInterface } from "src/app/components/comprobantes/modelos/cfdi3.3";
import { HttpService, TemaService } from "src/app/index.services";
import { ConfirmacionComponent } from "src/app/modals/confirmacion/confirmacion.component";
import { fadeInOut } from "./cancellation-table.animations";

interface CancelResponse {
  not_found_uuids?: string[];
  total_count: number;
  transactions: {
    href: string;
    id: string;
    status: string;
  }[];
}

type CancellationParameters = "uuid" | "motivo" | "folio_sustitucion";
interface PostCancelCompleted {
  requestCompleted: true;
  response: CancelResponse;
}
interface PostCancelIncompleted {
  requestCompleted: false;
  response: HttpErrorResponse;
}
export type PostCancelResponse = PostCancelCompleted | PostCancelIncompleted;
@Component({
  selector: "app-cancellation-table",
  templateUrl: "./cancellation-table.component.html",
  styleUrls: ["./cancellation-table.component.scss"],
  animations: [fadeInOut],
})
export class CancellationTableComponent implements OnInit {
  // clase pra uaso de underscore
  _under = new Underscore();
  /** lista de opciones de motivos */
  reasonsList: { label: string; value: string }[] = [
    {
      value: "01",
      label: "Comprobantes emitidos con errores con relación.",
    },
    {
      value: "02",
      label: "Comprobantes emitidos con errores sin relación.",
    },
    {
      value: "03",
      label: "No se llevó a cabo la operación.",
    },
    {
      value: "04",
      label: "Operación nominativa relacionada en una factura global.",
    },
  ];
  /** arreglo de formularios */
  formArrayCancellation: FormGroup[] = [];
  /** bandera de estaus de peticion */
  working = false;
  /** patron (pattern) dl uuid */
  uuidPattern =
    "[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}";
  /** mensaje de error si no se puede cancelr cfdis */
  msgError: string;
  /** mensaje de advertencia de cfdis omitidos  */
  msgCfdiOmited: string;
  /** rfc comun de los cfdi a cancelar */
  rfc: string;
  constructor(
    public _matDialog: MatDialog,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<CancellationTableComponent>,
    @Inject(MAT_DIALOG_DATA) public cfdiSel: CfdiInterface[],
    private _httpService: HttpService
  ) {
    const cfdiToCancel = this._under.getReadyToCancel(cfdiSel);
    const rfcSelected = Object.keys(cfdiToCancel);
    if (rfcSelected.length) {
      this.rfc = rfcSelected[0];
      const tempCFDIs: CfdiInterface[] = cfdiToCancel[this.rfc];
      if (tempCFDIs.length < cfdiSel.length) {
        this.msgCfdiOmited =
          "Algunos cfdi fueron omitidos debido a que no son aptos para ser cancelados o pertenecen a un RFC emisor diferente";
      }
      tempCFDIs.forEach((cfdi) => {
        this.formArrayCancellation.push(
          new FormGroup({
            uuid: new FormControl(cfdi.uuid, Validators.required),
            motivo: new FormControl(null, Validators.required),
            folio_sustitucion: new FormControl(null, [
              Validators.pattern(this.uuidPattern),
            ]),
          } as Record<CancellationParameters, FormControl>)
        );
      });
    } else {
      this.msgError =
        "Su selección no cuenta con CFDIs que pueden ser cancelados";
    }
  }

  ngOnInit(): void {}
  /* validForm XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * metodo para vaidar el formulario general
   */
  private validForm(): boolean {
    for (const form of this.formArrayCancellation) {
      for (const key in form.controls) {
        if (Object.prototype.hasOwnProperty.call(form.controls, key)) {
          form.controls[key].markAsTouched();
        }
      }
    }
    return (
      this.formArrayCancellation.findIndex((form) => {
        return !form.valid;
      }) === -1
    );
  }

  showWarning(): boolean {
    return this.formArrayCancellation.some(
      (group: FormGroup) => group.controls["motivo"].value === "01"
    );
  }

  /* onConfirmar XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * metodo para mandar a confirmar la cancelacion
   */
  onConfirmar(): void {
    if (this.validForm()) {
      const cancelBody = {
        rfc: this.rfc,
        folios: this.formArrayCancellation.map(
          (form): Record<CancellationParameters, string> => form.value
        ),
      };

      const modal = this._matDialog.open(ConfirmacionComponent, {
        maxHeight: "99%",
        maxWidth: "99%",
        disableClose: true,
        panelClass: "myModal",
        backdropClass: "myBackdrop",
        data: {
          msg: `¿Desea completar el proceso de cancelación?`,
        },
      });
      modal.afterClosed().subscribe((resp: "Aceptar" | "Cancelar") => {
        if (resp === "Aceptar") {
          this.working = true;
          this._httpService.postCancel(cancelBody).subscribe(
            (resp: CancelResponse) => {
              this.working = false;
              const response: PostCancelResponse = {
                requestCompleted: true,
                response: resp,
              };
              this.dialogRef.close(response);
            },
            (err) => {
              this.working = false;
              const response: PostCancelResponse = {
                requestCompleted: false,
                response: err,
              };
              this.dialogRef.close(response);
            }
          );
        }
      });
    }
  }
}
