import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { TemaService } from '../../index.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { HttpService } from '../../index.services';


@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*formulario 1(step 1) */
  formEmpresaInfo: FormGroup;

  textBtnStep1 = 'Validar';
  verCargaStep1 = false;

  validStep2 = false;


  formConfigPAC: FormGroup;
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*---- Servicios ----*/
    public _tema: TemaService,
    public dialogRef: MatDialogRef<EmpresaComponent>,
    public _httpService: HttpService
    /*---- Servicios ----*/
  ) {
    /*controles del formulario 1 */
    this.formEmpresaInfo = new FormGroup({
      name: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      neighborhood: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      outer_number: new FormControl('', [Validators.required]),
      inner_number: new FormControl(''),
      postal_code: new FormControl('', [Validators.required]),
      contacto: new FormControl('', [Validators.required]),
      mail_contacto: new FormControl('', [Validators.required]),
      respuestaBack: new FormControl()
    });


    this.formConfigPAC = new FormGroup({
      cer: new FormControl(null, [Validators.required]),
      key: new FormControl(null, [Validators.required]),
      pass_key: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.formEmpresaInfo.controls['respuestaBack'].setValidators([Validators.required]);
  }

  cargaArchivo(archivo, donde: string) {
    this.formConfigPAC.controls[donde].setValue(archivo);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close();
  }
  verificarEmpresa() {
    if (
      this.formEmpresaInfo.controls['name'].valid &&
      this.formEmpresaInfo.controls['rfc'].valid &&
      this.formEmpresaInfo.controls['country'].valid &&
      this.formEmpresaInfo.controls['state'].valid &&
      this.formEmpresaInfo.controls['city'].valid &&
      this.formEmpresaInfo.controls['neighborhood'].valid &&
      this.formEmpresaInfo.controls['street'].valid &&
      this.formEmpresaInfo.controls['outer_number'].valid &&
      this.formEmpresaInfo.controls['postal_code'].valid &&
      this.formEmpresaInfo.controls['contacto'].valid &&
      this.formEmpresaInfo.controls['mail_contacto'].valid
    ) {
      this.verCargaStep1 = true;
      this._httpService.postCompany(this.armarEmpresa()).subscribe(
        () => {
          this.textBtnStep1 = 'Siguiente';
          this.formEmpresaInfo.controls['respuestaBack'].setValue(true);
          this.verCargaStep1 = false;
          this.dialogRef.close('ok');
        },
        () => {
          this.verCargaStep1 = false;
        }
      );
    }
  }
  armarEmpresa() {
    return {
      'address': {
        'city': this.formEmpresaInfo.controls['city'].value,
        'country': this.formEmpresaInfo.controls['country'].value,
        'inner_number': this.formEmpresaInfo.controls['inner_number'].value + '',
        'neighborhood': this.formEmpresaInfo.controls['neighborhood'].value,
        'outer_number': this.formEmpresaInfo.controls['outer_number'].value + '',
        'postal_code': this.formEmpresaInfo.controls['postal_code'].value + '',
        'state': this.formEmpresaInfo.controls['state'].value,
        'street': this.formEmpresaInfo.controls['street'].value,
      },
      // 'group_id': "Occaecati voluptatem magni magnam sit voluptatem architecto.",
      'name': this.formEmpresaInfo.controls['name'].value,
      'rfc': this.formEmpresaInfo.controls['rfc'].value,
      'contact_name': this.formEmpresaInfo.controls['contacto'].value,
      'contact': this.formEmpresaInfo.controls['mail_contacto'].value
    };
  }
}
