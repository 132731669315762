import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { FormControl } from "@angular/forms";
import { TemaService } from "../../index.services";
import { EditUserPaypalComponent } from "../../modals/edit-user-paypal/edit-user-paypal.component";
import { MatDialog } from "@angular/material";
import { Message } from "primeng/api";
import { getAuthUser } from "../../services/auth-token";
import { editUserNotifications } from "../../modals/edit-user-paypal/edit-user-notifications";
import { BusinessNameNotificationComponent } from "src/app/modals/business-name-notification/business-name-notification.component";

@Component({
  selector: "app-comprobantes",
  templateUrl: "./comprobantes.component.html",
  styleUrls: ["./comprobantes.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ComprobantesComponent implements OnInit {
  msgs: Message[] = [];
  modo = new FormControl("basico");

  constructor(
    public _tema: TemaService,
    public breakpointObserver: BreakpointObserver,
    public dialog: MatDialog
  ) {
    const loggedUser = getAuthUser();
    if (loggedUser.completedAuthorized && !loggedUser.fiscalFields) {
      this.openConfigUser();
    } else if (!loggedUser.notify.businessName) {
      this.openUpdateBusinessName();
    }
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(["(max-width: 768px)"])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.modo.setValue("basico");
        }
      });
  }

  openConfigUser() {
    const modal = this.dialog.open(EditUserPaypalComponent, {
      disableClose: true,
      maxHeight: "99%",
      maxWidth: "99%",
      data: {
        id: null,
        disableClose: true,
        title: "Completar usuario",
        complete: true,
      },
    });
    modal.afterClosed().subscribe((msg) => {
      this.msgs = [];
      const notification = editUserNotifications[msg];
      if (notification) {
        this.msgs.push(notification);
      }
    });
  }
  /* openUpdateBusinessName XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * method to open the modal to update company name
   */
  openUpdateBusinessName(): void {
    this.dialog
      .open(BusinessNameNotificationComponent, {
        disableClose: true,
        maxHeight: "99%",
        maxWidth: "99%",
      })
      .afterClosed()
      .subscribe((msg) => {
        this.msgs = [];
        const notification = editUserNotifications[msg];
        if (notification) {
          this.msgs.push(notification);
        }
      });
  }
}
