import { Injectable } from "@angular/core";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class PermisosService {
  private listaPermisos = [];
  mostar = {
    empEmi: false,
    usuario: false,
    configSat: false,
    admin: false,
  };
  verXmlPDF = false;
  verCancel = false;
  verEmali = false;

  constructor() {
    if (JSON.parse(localStorage.getItem("usuario"))) {
      let data = JSON.parse(localStorage.getItem("usuario"))["token"];
      data = jwt_decode(data);
      this.setPermisos(data.permissions);
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo mandar los permisos que tien el usuario ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getGuardPermiso(donde): boolean {
    for (let index = 0; index < this.listaPermisos.length; index++) {
      const element = this.listaPermisos[index];
      if (element === "users.crud" && donde === "usuarios") {
        return true;
      }
      if (element === "company.crud" && donde === "empresas_emisoras") {
        return true;
      }
      if (
        element === "cert.upload" &&
        (donde === "certificados" || donde === "timbres_fiscales")
      ) {
        return true;
      }
    }
    return false;
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo pra asignar los permisos que tiene el usuario (se obtienen del token) ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  setPermisos(permisos) {
    this.listaPermisos = permisos;
    for (let index = 0; index < permisos.length; index++) {
      const element = permisos[index];
      switch (element) {
        case "users.crud":
          this.mostar.usuario = true;
          break;
        case "company.crud":
          this.mostar.empEmi = true;
          break;
        case "cert.upload":
          this.mostar.configSat = true;
          break;
        case "cfdi.files":
          this.verXmlPDF = true;
          break;
        case "cfdi.cancel":
          this.verCancel = true;
          break;
        case "cfdi.mail":
          this.verEmali = true;
          break;
      }
    }
    this.mostar.admin = this.mostar.usuario || this.mostar.empEmi;
  }
  limpiarPermisis() {
    this.listaPermisos = [];
    this.mostar = {
      empEmi: false,
      usuario: false,
      configSat: false,
      admin: false,
    };
    this.verXmlPDF = false;
    this.verCancel = false;
    this.verEmali = false;
  }
}
