import { Injectable } from "@angular/core";
import { endPoint } from "./endpoint";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
// import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class HttpService {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  token: string;
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*---- servicio ----*/
    public _httpClient: HttpClient /*---- servicio ----*/
  ) {
    // this.actualizarToken();
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== mertodo para actualizar el token guardado===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  actualizarToken() {
    this.token = JSON.parse(localStorage.getItem("usuario")).token;
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodos para EMPRESAS EMISORAS ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*obtener todas las empresas */
  getCompanies() {
    this.actualizarToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(endPoint.companies, { headers: headers });
  }
  /*obtener solo una empresa por el RFC */
  getCompany(rfc: string) {
    this.actualizarToken();
    const url = endPoint.companies + "/" + rfc;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  /*crear una compañia */
  postCompany(data) {
    this.actualizarToken();
    const body = data;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(endPoint.companies, data, {
      headers: headers,
    });
  }
  /*eliminar una compañia */
  deleteCompany(rfc: string) {
    this.actualizarToken();
    const url = endPoint.companies + "/" + rfc;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.delete(url, { headers: headers });
  }
  /*modificar una compañia */
  putCompany(rfc: string, data) {
    this.actualizarToken();
    const url = endPoint.companies + "/" + rfc;
    const body = data;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.put(url, body, { headers: headers });
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodos para USUARIOS ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*obtener todos los usuarios */
  getUsers(page, filtro: string = "") {
    this.actualizarToken();
    let pagination = "";
    if (page === null) {
      pagination = "limit=20&offset=0";
    } else {
      pagination = this.getUrlPageUser(page);
      pagination = pagination.slice(0, pagination.length - 1);
    }
    const url = endPoint.users + "?" + "name=" + filtro + "&" + pagination;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  private getUrlPageUser(filtros) {
    let parametros = "";
    for (const key in filtros) {
      if (filtros.hasOwnProperty(key)) {
        const element = filtros[key];
        parametros += key + "=" + encodeURIComponent(element) + "&";
      }
    }
    return parametros;
  }
  /*obtener solo un usuario por el id */
  getUser(id: string) {
    this.actualizarToken();
    const url = endPoint.users + "/" + id;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  /*crear un nuevo usuario */
  postUser(infoUser) {
    this.actualizarToken();
    const body = infoUser;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(endPoint.users, body, { headers: headers });
  }
  /*borrar un usuario */
  deleteUser(id: number) {
    this.actualizarToken();
    const url = endPoint.users + "/" + id;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.delete(url, { headers: headers });
  }
  /*=== metodo para detener la facturacion ===*/
  stopInvoices(myID) {
    const url = endPoint.users + "/" + myID + "/stopinvoices";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.patch(url, null, { headers: headers });
  }

  /*=== metodo para verificar que el cambio este permitido de la data del u ===*/
  validaUserRFC(myRFC) {
    this.actualizarToken();
    const url = endPoint.users + "/validate?rfc=" + myRFC;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  /*modificar un usuario */
  patchUser(id: string, data) {
    this.actualizarToken();
    const url = endPoint.users + "/" + id;
    const body = { ...data, notify: { businessName: true } };
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.patch(url, body, { headers: headers });
  }
  /*obtener my usuario */
  getMyUser() {
    this.actualizarToken();
    const url = endPoint.users + "/owner";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  /*modificar usuarios (yo mismo) */
  patchMyUser(data) {
    this.actualizarToken();
    const url = endPoint.users + "/owner";
    const body = { ...data, notify: { businessName: true } };
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.patch(url, body, { headers: headers });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodos para COMPROBANTES ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getXML(transactionID) {
    this.actualizarToken();
    const url = endPoint.transactions + "/" + transactionID + "/xml";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    // headers.append('Content-Type', 'text/xml');
    return this._httpClient
      .get(url, {
        headers: headers,
        responseType: "blob",
        reportProgress: true,
        observe: "response",
      })
      .pipe(
        map((myResp) => {
          const fileName = myResp.headers.get("file-name");
          const R: { data: Blob; extension: string } = {
            data: myResp.body,
            extension: fileName,
          };
          return R;
        })
      );
  }
  getPDF(transactionID) {
    this.actualizarToken();
    const url = endPoint.transactions + "/" + transactionID + "/pdf";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    // headers.append('Content-Type', 'text/xml');
    return this._httpClient
      .get(url, {
        headers: headers,
        responseType: "blob",
        reportProgress: true,
        observe: "response",
      })
      .pipe(
        map((myResp) => {
          const fileName = myResp.headers.get("file-name");
          const R: { data: Blob; extension: string } = {
            data: myResp.body,
            extension: fileName,
          };
          return R;
        })
      );
  }
  getSearchTransactions(filtros, offset, limit, fitrosTable) {
    let filtrosDeTable = "";
    if (fitrosTable !== null) {
      filtrosDeTable = this.getUrlFilTable(fitrosTable);
    }
    this.actualizarToken();
    const url =
      endPoint.transactions +
      "?" +
      this.getUrlFiltros(filtros) +
      filtrosDeTable +
      "limit=" +
      limit +
      "&offset=" +
      offset;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }

  private getUrlFiltros(filtros): string {
    let parametros = "";
    for (const key in filtros) {
      if (filtros.hasOwnProperty(key)) {
        let element = filtros[key];
        if (element !== "") {
          if (key === "fechaFinal") {
            const tempDate = new Date((<Date>element).setHours(23, 59, 59));
            element = tempDate.toISOString();
          }
          if (key === "fechaInicial") {
            const tempDate = new Date((<Date>element).setHours(0, 0, 0));
            element = tempDate.toISOString();
          }
          parametros +=
            this.mapNameFiltro(key) + "=" + encodeURIComponent(element) + "&";
        }
      }
    }
    return parametros;
  }
  private mapNameFiltro(name): string {
    let correct = "";
    switch (name) {
      case "estatus":
        correct = "status";
        break;

      case "fechaFinal":
        correct = "date-End";
        break;

      case "fechaInicial":
        correct = "date-Initial";
        break;

      case "folio":
        correct = "folio";
        break;

      case "rfcEmisor":
        correct = "emisorRFC";
        break;

      case "rfcReceptor":
        correct = "receptorRFC";
        break;

      case "serie":
        correct = "serie";
        break;
      case "paypalID":
        correct = "paypalID";
        break;
    }
    return correct;
  }
  private getUrlFilTable(filtros) {
    let parametros = "";
    for (const key in filtros) {
      if (filtros.hasOwnProperty(key)) {
        const element = filtros[key];
        parametros += key + "=" + encodeURIComponent(element) + "&";
      }
    }
    return parametros;
  }
  getSearchTransactionsBas(busqueda, page: { limit: number; offset: number }) {
    this.actualizarToken();
    let tempURL: string = this.getUrlFilTable(busqueda);
    tempURL = tempURL.slice(0, tempURL.length - 1);
    const url =
      endPoint.transactions +
      "?" +
      tempURL +
      "&status=APPROVED" +
      "&limit=" +
      page.limit +
      "&offset=" +
      page.offset;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  postCancel(listaCFDI) {
    this.actualizarToken();
    const url = endPoint.transactions + "/multi-cancel";
    const body = listaCFDI;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(url, body, { headers: headers });
  }
  postMail(id, data) {
    this.actualizarToken();
    const url = endPoint.transactions + "/" + id + "/mail";
    const body = data;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(url, body, { headers: headers });
  }
  getCSV(filtros, fitrosTable) {
    let filtrosDeTable = "";
    if (fitrosTable !== null) {
      filtrosDeTable = this.getUrlFilTable(fitrosTable);
    }
    this.actualizarToken();
    let url =
      endPoint.transactions +
      "/csv?" +
      this.getUrlFiltros(filtros) +
      filtrosDeTable;
    url = url.slice(0, url.length - 1);
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, {
      headers: headers,
      responseType: "blob",
    });
  }
  getListPDF(filtros, fitrosTable) {
    let filtrosDeTable = "";
    if (fitrosTable !== null) {
      filtrosDeTable = this.getUrlFilTable(fitrosTable);
    }
    this.actualizarToken();
    let url =
      endPoint.transactions +
      "/list-pdf?" +
      this.getUrlFiltros(filtros) +
      filtrosDeTable;
    url = url.slice(0, url.length - 1);
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, {
      headers: headers,
      responseType: "blob",
      reportProgress: true,
    });
  }
  getListXML(filtros, fitrosTable) {
    let filtrosDeTable = "";
    if (fitrosTable !== null) {
      filtrosDeTable = this.getUrlFilTable(fitrosTable);
    }
    this.actualizarToken();
    let url =
      endPoint.transactions +
      "/list-xml?" +
      this.getUrlFiltros(filtros) +
      filtrosDeTable;
    url = url.slice(0, url.length - 1);
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, {
      headers: headers,
      responseType: "blob",
      reportProgress: true,
    });
  }
  postMasivXML(idList) {
    this.actualizarToken();
    const url = endPoint.transactions + "/multi-xml";
    const body = { transaction_ids: idList };
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(url, body, {
      headers: headers,
      responseType: "blob",
      reportProgress: true,
    });
  }
  postMasivPDF(idList) {
    this.actualizarToken();
    const url = endPoint.transactions + "/multi-pdf";
    const body = { transaction_ids: idList };
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(url, body, {
      headers: headers,
      responseType: "blob",
      reportProgress: true,
    });
  }
  postReTimbre(id) {
    this.actualizarToken();
    const url = endPoint.transactions + "/" + id + "/send-request";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.post(url, null, { headers: headers });
  }
  getSat(id) {
    this.actualizarToken();
    const url = endPoint.transactions + "/" + id + "/sat";
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(url, { headers: headers });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Grupos (Filtros) ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getGroups() {
    this.actualizarToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(endPoint.groups, { headers: headers });
  }
  putGroups(id, newGrupo) {
    this.actualizarToken();
    const url = endPoint.groups + "/" + id;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.put(url, newGrupo, { headers: headers });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== reportes ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para reporte de folio auxiliar de cfdi  ===*/
  getReporteFolios(myParametros) {
    const getParamsUrl = (myFiltros) => {
      let temp = "";
      for (const key in myFiltros) {
        if (myFiltros.hasOwnProperty(key)) {
          const element = myFiltros[key];
          temp += key + "=" + encodeURIComponent(element) + "&";
        }
      }
      if (temp.length) {
        temp = temp.slice(0, temp.length - 1);
      }
      return temp;
    };
    const myUrl = endPoint.reports + "/folios?" + getParamsUrl(myParametros);
    this.actualizarToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(myUrl, {
      headers: headers,
      responseType: "blob",
    });
  }
  /*=== metodo para reporte de usuarios  ===*/
  getReportMerchants() {
    const myUrl = endPoint.reports + "/merchants";
    this.actualizarToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });
    return this._httpClient.get(myUrl, {
      headers: headers,
      responseType: "blob",
    });
  }
}
