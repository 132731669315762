import { url } from './baseUrl';

export const epLogin = {
    login: url + '/login'
};

export const endPoint = {
    companies: url + '/companies',
    users: url + '/users',
    transactions: url + '/transactions',
    groups: url + '/groups',
    reports: url + '/reports'
};
