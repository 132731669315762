import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

/* interface <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
export interface ButtonsConfiguration {
  okBtnLabel: string;
  noBtnLabel: string;
}
export interface NotificationConfigData {
  message: string;
  buttonsConfig: string | ButtonsConfiguration;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> interface */
@Component({
  selector: "app-configurable-notification",
  templateUrl: "./configurable-notification.component.html",
  styleUrls: ["./configurable-notification.component.css"],
})
export class ConfigurableNotificationComponent implements OnInit {
  isNotificationModal: boolean;
  buttonsLabels: ButtonsConfiguration = {
    noBtnLabel: "",
    okBtnLabel: "",
  };
  constructor(
    /* servicios <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
    @Inject(MAT_DIALOG_DATA) public notificationConfig: NotificationConfigData,
    public _matDialogRef: MatDialogRef<ConfigurableNotificationComponent>
  ) {
    this.isNotificationModal =
      typeof notificationConfig.buttonsConfig === "string";
    if (this.isNotificationModal) {
      this.buttonsLabels.okBtnLabel =
        notificationConfig.buttonsConfig as string;
    } else {
      this.buttonsLabels = {
        ...(notificationConfig.buttonsConfig as ButtonsConfiguration),
      };
    }
  }

  ngOnInit() {}
}
