import { Component, OnInit } from '@angular/core';
import { Imagen } from '../../interface/interface';
import { MatDialogRef } from '@angular/material';
import { Underscore } from '../../classes/underscore';
import { TemaService } from '../../index.services';

@Component({
  selector: 'app-identidad',
  templateUrl: './identidad.component.html',
  styleUrls: ['./identidad.component.css']
})
export class IdentidadComponent implements OnInit {
  _under = new Underscore();
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*valor del color seleccionado */
  selccion: number;
  /*lista de temas de toda la aplicaccion*/
  theme = [
    'red0',
    'pink0',
    'purple0',
    'deep-purple0',
    'indigo0',
    'blue0',
    'green0',
    'yellow0',
    'brown0',
    'grey0',
    'blue-grey0',
    // ------
    'red1',
    'pink1',
    'purple1',
    'deep-purple1',
    'indigo1',
    'blue1',
    'green1',
    'yellow1',
    'brown1',
    'grey1',
    'blue-grey1',
    // ------
    'red2',
    'pink2',
    'purple2',
    'deep-purple2',
    'indigo2',
    'blue2',
    'green2',
    'yellow2',
    'brown2',
    'grey2',
    'blue-grey2'
  ];
  /*lista de colores para la selccion (clases de css) */
  colores = [
    'Sred0',
    'Spink0',
    'Spurple0',
    'Sdeep-purple0',
    'Sindigo0',
    'Sblue0',
    'Sgreen0',
    'Syellow0',
    'Sbrown0',
    'Sgrey0',
    'Sblue-grey0',
    // ------
    'Sred1',
    'Spink1',
    'Spurple1',
    'Sdeep-purple1',
    'Sindigo1',
    'Sblue1',
    'Sgreen1',
    'Syellow1',
    'Sbrown1',
    'Sgrey1',
    'Sblue-grey1',
    // ------
    'Sred2',
    'Spink2',
    'Spurple2',
    'Sdeep-purple2',
    'Sindigo2',
    'Sblue2',
    'Sgreen2',
    'Syellow2',
    'Sbrown2',
    'Sgrey2',
    'Sblue-grey2'
  ];
  /*imagen seleccionada */
  img: string | ArrayBuffer;

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(public _tema: TemaService, /* private _under: UnderscoreService,*/
    public dialogRef: MatDialogRef<IdentidadComponent>) {
    this.selccion = this._under.getIndex(_tema.tema, this.theme);
  }

  ngOnInit() {
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para seleccionar y asignar el
  color del tema ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  onSelccion(itemSelccion: number) {
    this.selccion = itemSelccion;
    this._tema.tema = this.theme[itemSelccion];
    this._tema.saveTema();
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostrar la img seccionada ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  setImg(eve: Imagen) {
    this.img = eve.direccion;
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para asignar ela imgane al tema
  de la app ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  saveImg() {
    this._tema.setLogo(this.img);
    this._tema.saveTema();
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== salit el modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  regresar() {
    this.dialogRef.close();
  }

}
