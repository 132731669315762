import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {
  statusLogin = 0;

  formLogin: FormGroup;

  constructor(
    /*=========================Servicios=========================*/
    public _loginService: LoginService,
    private _router: Router,
    public dialogRef: MatDialogRef<LoginAdminComponent>
    /*=========================Servicios=========================*/
  ) {
    this.formLogin = new FormGroup({
      user: new FormControl('', [Validators.required]),
      pass: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para hacer login ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onEntrar() {
    this.formLogin.get('user').markAsTouched();
    this.formLogin.get('pass').markAsTouched();
    if (this.formLogin.valid) {
      this.statusLogin = 1;
      this._loginService.login(this.formLogin.value).subscribe(
        () => {
          this._loginService.setLoged();
          this._router.navigate(['/indicador/comprobantes']);
          this.dialogRef.close();
        },
        () => {
          this.statusLogin = 2;
        });
    }
  }

}
