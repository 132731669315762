import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemaService, HttpService } from '../../index.services';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*formulario de usuario */
  formEditUser: FormGroup;
  /*informacion del usuario */
  infoUser = null;

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    private _httpService: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialogRef: MatDialogRef<EditUserComponent>,
    public _tema: TemaService
    /*=====Servicios=====*/
  ) {
    const idUser = (data.info.id);
    _httpService.getUser(idUser).subscribe(
      resp => {
        this.infoUser = resp;
      },
      err => { });

    /*controles del formulario */
    this.formEditUser = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    /*pre-llenado del formulario */
    this.formEditUser.controls['nombre'].setValue(this.data.info['name']);
    this.formEditUser.controls['apellido'].setValue(this.data.info['last_name']);
    this.formEditUser.controls['correo'].setValue(this.data.info['email']);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this._matDialogRef.close();
  }

  guardar() {
    const cambiosUser = {
      // company_rfcs: this.infoUser.company_rfcs,
      // permissions: this.infoUser.permissions,
      // receptor_rfcs: this.infoUser.receptor_rfcs,
      // type: this.infoUser.type,
      // valid: this.infoUser.valid,

      name: this.formEditUser.controls['nombre'].value,
      last_name: this.formEditUser.controls['apellido'].value,
      email: this.formEditUser.controls['correo'].value
    };
    if (this.formEditUser.valid) {
      this._httpService.patchUser(this.infoUser.id, cambiosUser).subscribe(
        () => {
        this._matDialogRef.close('ok');
        },
        () => {
        this._matDialogRef.close('error');
        }
      );
    }
  }
}
