import { ConfirmacionComponent } from "./../../modals/confirmacion/confirmacion.component";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginPaypalService } from "../../services/login-paypal.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LoginService } from "src/app/services/login.service";
import { MatDialog } from "@angular/material";
import { NotificationComponent } from "../../modals/notification/notification.component";
import { TaxRegimeCatalog } from "../../sat-catalogs/tax-regime";
import { NotificationService } from "src/app/services/notifications/notification.service";

@Component({
  selector: "app-paypal-validation-component",
  templateUrl: "./paypal-validation.component.html",
  styleUrls: ["./paypal-validation.component.scss"],
})
export class PaypalValidationComponent implements OnInit {
  /*=========================Variables=========================*/
  verRegisterForm = "false";
  nombrePP = "sin nombre";
  disabledBtn = false;
  formNewUser: FormGroup;
  taxRegimeCatalog = TaxRegimeCatalog;
  yearNow = new Date().getFullYear();
  confirmedInformation = new FormControl(false);

  constructor(
    /*=========================Servicios=========================*/
    private _ActivatedRoute: ActivatedRoute,
    private _LoginPaypalService: LoginPaypalService,
    public _LoginService: LoginService,
    private _Router: Router,
    public _matDialog: MatDialog,
    private _notificationService: NotificationService
  ) {
    this.formNewUser = new FormGroup({
      token: new FormControl(null),
      receptor_rfc: new FormControl(null, [Validators.required]),
      business_name: new FormControl(null, [Validators.required]),
      zip_code: new FormControl(null, [Validators.required]),
      tax_regime: new FormControl(null, [Validators.required]),
      past_cfdis: new FormControl(false),
      terminos: new FormControl(false, [Validators.requiredTrue]),
    });
    const paramsPaypal: {} = this._ActivatedRoute.queryParams["_value"];
    if (paramsPaypal.hasOwnProperty("code")) {
      _LoginPaypalService.loginPaypal(paramsPaypal["code"]).subscribe(
        (resp: { message: string; token: string; user_name: string }) => {
          if (resp) {
            this.formNewUser.get("token").setValue(resp.token);
            this.nombrePP = resp.user_name;
            this.verRegisterForm = "true";
          } else {
            this._LoginService.setLoged();
            const isDownload = localStorage.getItem("downloadData");
            if (isDownload) {
              this._Router.navigate(["/descargar"]);
            } else {
              this._Router.navigate(["/indicador/comprobantes"]);
            }
          }
        },
        () => {
          this.verRegisterForm = "error";
        }
      );
    } else {
      _LoginService.logout();
    }
  }

  ngOnInit() {}

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mandar la informacion del formulario ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  newUser() {
    this.formNewUser.get("terminos").markAsTouched();
    if (this.formNewUser.valid) {
      if (this.confirmedInformation.value) {
        this.disabledBtn = true;
        let { terminos, ...user } = this.formNewUser.value;
        const newUser = { validate: false, ...user };
        newUser["business_name"] = newUser["business_name"].toUpperCase();
        this._LoginPaypalService.signInPaypal(newUser).subscribe(
          () => {
            const modal = this._matDialog.open(NotificationComponent, {
              data: { statusOK: true, msg: "Usuario registrado correctamente" },
            });
            modal.afterClosed().subscribe(() => {
              this._LoginService.setLoged();
              const isDownload = localStorage.getItem("downloadData");
              if (isDownload) {
                this._Router.navigate(["/descargar"]);
              } else {
                this._Router.navigate(["/indicador/comprobantes"]);
              }
            });
          },
          (err) => {
            if (err.error.message === "rfc in use") {
              const modal = this._matDialog.open(ConfirmacionComponent, {
                data: {
                  msg: `El RFC ingresado ya está en uso. ¿Desea continuar?`,
                },
              });
              modal.afterClosed().subscribe((msg) => {
                if (msg === "Aceptar") {
                  newUser.validate = true;
                  this._LoginPaypalService.signInPaypal(newUser).subscribe(
                    () => {
                      const modalResp = this._matDialog.open(
                        NotificationComponent,
                        {
                          data: {
                            statusOK: true,
                            msg: "¡Usuario registrado correctamente!",
                          },
                        }
                      );
                      modalResp.afterClosed().subscribe(() => {
                        this._LoginService.setLoged();
                        const isDownload = localStorage.getItem("downloadData");
                        if (isDownload) {
                          this._Router.navigate(["/descargar"]);
                        } else {
                          this._Router.navigate(["/indicador/comprobantes"]);
                        }
                      });
                    },
                    (err_validate) => {
                      if (err_validate.error.message === "rfc not valid") {
                        this.disabledBtn = false;
                        this._matDialog.open(NotificationComponent, {
                          data: {
                            statusOK: false,
                            msg: "El RFC ingresado no está permitido por el sistema",
                          },
                        });
                      } else if (
                        err_validate.error.message ===
                        "RFC not valid in rfc check"
                      ) {
                        this.disabledBtn = false;
                        this._matDialog.open(NotificationComponent, {
                          data: {
                            statusOK: false,
                            msg: "El RFC ingresado no es válido o no está registrado en la lista de contribuyentes del SAT",
                          },
                        });
                      } else {
                        this._matDialog.open(NotificationComponent, {
                          data: {
                            statusOK: false,
                            msg: "¡Error al registrar el usuario!",
                          },
                        });
                        this.verRegisterForm = "error";
                      }
                    }
                  );
                } else {
                  this.disabledBtn = false;
                }
              });
            } else if (err.error.message === "rfc not valid") {
              this.disabledBtn = false;
              this._matDialog.open(NotificationComponent, {
                data: {
                  statusOK: false,
                  msg: "El RFC ingresado no está permitido por el sistema",
                },
              });
            } else if (err.error.message === "RFC not valid in rfc check") {
              this.disabledBtn = false;
              this._matDialog.open(NotificationComponent, {
                data: {
                  statusOK: false,
                  msg: "El RFC ingresado no es válido o no está registrado en la lista de contribuyentes del SAT",
                },
              });
            } else {
              this._matDialog.open(NotificationComponent, {
                data: {
                  statusOK: false,
                  msg: "¡Error al registrar el usuario!",
                },
              });
              this.verRegisterForm = "error";
            }
          }
        );
      } else {
        this._notificationService.openNotification(
          "Por favor introduce los datos tal cual aparecen en tu Constancia de Situación Fiscal, de otra manera PayPal México no podrá emitir tus CFDIs por las nuevas disposiciones del SAT."
        );
      }
    }
  }
}
