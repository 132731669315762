import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { TemaService } from '../../index.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnDestroy {
  eveClose: Subscription;
  eveDownload: Subscription;


  value = 0;

  constructor(
    /*=====Servicios=====*/
    private _loadingService: LoadingService,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<LoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { total: number },
    /*=====Servicios=====*/
  ) { }

  ngOnInit() {

    this.eveClose = this._loadingService.eveCerrar.subscribe(((completo: boolean) => {
      if (completo) {
        this.value = 100;
        setTimeout(() => {
          this.onClose();
        }, 600);
      } else {
        this.onClose();
      }
    }));
    this.eveDownload = this._loadingService.eveDescaraga.subscribe((progres: number) => {
      this.value = this.upDateValue(progres);
    });
  }
  ngOnDestroy() {
    this.eveClose.unsubscribe();
    this.eveDownload.unsubscribe();
  }
  onClose() {
    this.dialogRef.close();
  }

  upDateValue(progreso: number): number {

    let temp = (progreso * 100) / this.data.total;
    if (temp >= 100) {
      temp = 99.9;
    }
    return temp;
  }

  // formatBytesUnid(bytes, decimals) {
  //   if (bytes === 0) { return '0 Bytes'; }
  //   const k = 1024,
  //     dm = decimals <= 0 ? 0 : decimals || 2,
  //     sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //     i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }
  // formatBytes(bytes, decimals) {
  //   if (bytes === 0) { return '0 Bytes'; }
  //   const k = 1024,
  //     dm = decimals <= 0 ? 0 : decimals || 2,
  //     sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //     i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  // }
}
