import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Message } from 'primeng/api';
import { MatDialog } from '@angular/material';
import { AddTimbreComponent } from '../../modals/add-timbre/add-timbre.component';
import { VerTimbradorComponent } from '../../modals/ver-timbrador/ver-timbrador.component';
import { SolicitudTimbreComponent } from '../../modals/solicitud-timbre/solicitud-timbre.component';
interface Timbre {
  id: number;
  rfc: string;
  timbreInicio: number;
  timbreFin: number;
  timbreActual: number;
  timbreAlerta: number;
  fecha: Date;
  estatus: string;
}

@Component({
  selector: 'app-timbres-fiscales',
  templateUrl: './timbres-fiscales.component.html',
  styleUrls: ['./timbres-fiscales.component.css']
})
export class TimbresFiscalesComponent implements OnInit {
  msgs: Message[] = [];

  opciones: MenuItem[];
  selccion: Timbre;

  timbres: Timbre[];

  constructor(public _matDialog: MatDialog) {
    this.timbres = [
      {
        id: 35828,
        rfc: 'YUI5684HKLJ51',
        timbreInicio: 5,
        timbreFin: 105,
        timbreActual: 42,
        timbreAlerta: 100,
        fecha: new Date(),
        estatus: 'activo'
      },
      {
        id: 17890,
        rfc: 'GHOLN45638DH21',
        timbreInicio: 59,
        timbreFin: 159,
        timbreActual: 105,
        timbreAlerta: 155,
        fecha: new Date(),
        estatus: 'activo'
      }
    ];
    this.opciones = [
      {
        label: 'Eliminar', icon: ' icon-cross2',
        command: (eve) => {
          this.eliminar();
        }
      }
    ];
  }

  ngOnInit() {
  }
  openAddTimbre() {
    const modalEmp = this._matDialog.open(AddTimbreComponent,
      {
        width: '400px'
      }
    );
  }
  openVerTimbrador() {
    const modalEmp = this._matDialog.open(VerTimbradorComponent,
      {
        width: '400px'
      }
    );
  }
  openSolicitarTimbre() {
    const modalEmp = this._matDialog.open(SolicitudTimbreComponent,
      {
        width: '400px'
      }
    );
  }

  eliminar() {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'Certificado Eliminada' });
  }
}
