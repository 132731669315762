import { Component, OnInit } from '@angular/core';
import { animationPayPal } from './paypal-login.animation';

import { Renderer2, Inject } from '@angular/core';
// import { DOCUMENT } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { LoginAdminComponent } from '../modals/login-admin/login-admin.component';
import { configBtn } from '../services/baseUrl';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-paypal-login',
  templateUrl: './paypal-login.component.html',
  styleUrls: ['./paypal-login.component.scss'],
  animations: animationPayPal
})
export class PaypalLoginComponent implements OnInit {
  logosPaypal = 'out';
  logosDetecno = false;

  constructor(
    /*=========================Servicios=========================*/
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    public _matDialog: MatDialog,
    /*=========================Servicios=========================*/
  ) { }

  ngOnInit() {
    const s1 = this.renderer2.createElement('script');
    s1.type = 'text/javascript';
    s1.src = 'https://www.paypalobjects.com/js/external/connect/api.js';
    s1.onload = this.nextScript.bind(this);
    this.renderer2.appendChild(this._document.body, s1);
  }

  nextScript() {
    const s2 = this.renderer2.createElement('script');

    s2.text = configBtn;

    // s2.text = `
    // paypal.use( ['login'], function (login) {
    //   login.render ({
    //     "appid":"AcDzItbiaxiQnJ5ISzNgiBY0OUBiIsDhiHTa-Gf3jZhQE0UhyDA9g1q4GT8VECr8X6jzP_1v-0p2rQ0p",
    //     "authend":"sandbox",
    //     "scopes":"openid profile email address https://uri.paypal.com/services/paypalattributes",
    //     "containerid":"cwppButton",
    //     "responseType":"code",
    //     "locale":"en-us",
    //     "buttonType":"CWP",
    //     "buttonShape":"pill",
    //     "buttonSize":"lg",
    //     "fullPage":"true",
    //     "returnurl":"http://localhost:4200/payPal"
    //   });
    // });
    // `;

    this.renderer2.appendChild(this._document.body, s2);
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de login de administrador  ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openLoginAdmin() {
    const modal = this._matDialog.open(LoginAdminComponent);
  }
}
