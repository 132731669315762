import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from './../../services/http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-reporte-aux',
  templateUrl: './reporte-aux.component.html',
  styleUrls: ['./reporte-aux.component.scss']
})
export class ReporteAuxComponent implements OnInit {
  /* etiquet anchor para descargar docs (para que fucione con firefox) */
  @ViewChild('anchorDownload') anchorDownload: ElementRef;
  isLoading = false;
  formRepoAux: FormGroup;
  listaMeses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  listaYears: number[] = [];
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /* servicios <=========================================================================== */
    public dialogRef: MatDialogRef<ReporteAuxComponent>,
    private _httpService: HttpService,
    // @Inject(MAT_DIALOG_DATA) public data: { id: string }
    /*===========================================================================> servicios */
  ) {
    let iYears = 2015;
    const fYears = 2099;
    while (iYears <= fYears) {
      this.listaYears.push(iYears);
      iYears++;
    }
    this.formRepoAux = new FormGroup({
      tipo_solicitud: new FormControl(null, [Validators.required]),
      num_orden: new FormControl(null, [Validators.required]),
      num_tramite: new FormControl(null, [Validators.required]),
      mes: new FormControl(null, [Validators.required]),
      anio: new FormControl(null, [Validators.required]),
      fecha_inicial: new FormControl(null, [Validators.required]),
      fecha_final: new FormControl(null, [Validators.required])
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== ngOnInit ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  ngOnInit() {
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar el reporte ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onDownload() {
    for (const key in this.formRepoAux.controls) {
      if (this.formRepoAux.controls.hasOwnProperty(key)) {
        const element = this.formRepoAux.controls[key];
        element.markAsTouched();
      }
    }
    if (this.formRepoAux.valid) {
      const repoSearch: { tipo_solicitud, num_orden, num_tramite, mes, anio, fecha_inicial, fecha_final } = this.formRepoAux.value;
      repoSearch.fecha_inicial = this.getferchaFromat(repoSearch.fecha_inicial);
      repoSearch.fecha_final = this.getferchaFromat(repoSearch.fecha_final);
      this.isLoading = true;

      this._httpService.getReporteFolios(this.formRepoAux.value).subscribe(
        (resp: Blob) => {
          const data = window.URL.createObjectURL(resp);
          this.anchorDownload.nativeElement.href = data;
          this.anchorDownload.nativeElement.download = 'Reporte auxiliar' + this.getferchaFromat(new Date()) + '.xml';
          this.anchorDownload.nativeElement.click();
          this.dialogRef.close();
        },
        () => {
          this.dialogRef.close(true);
        }
      );
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para  dar formato a la fecha ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  private getferchaFromat(myDate: Date) {
    const setTwoDig = (myNumber: string) => {
      if (myNumber.length === 1) {
        return '0' + myNumber;
      }
      return myNumber;
    };
    return myDate.getFullYear() + '-' + setTwoDig((myDate.getMonth() + 1) + '') + '-' + setTwoDig(myDate.getDate() + '');
  }

}
