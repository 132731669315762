import { CfdiInterface } from "../components/comprobantes/modelos/cfdi3.3";

export class Underscore {
  getIndex(key: string, list: Array<any>): number {
    return list.findIndex((item) => item === key);
  }

  getReadyToCancel(CFDIList) {
    const cfdisToCancel = {};
    const groups = this.groupBy(CFDIList, "rfcEmisor");
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        const filteredCfdis = this.filterCfdis(groups[key]);
        if (filteredCfdis.length > 0) {
          cfdisToCancel[key] = filteredCfdis;
        }
      }
    }
    return cfdisToCancel;
  }

  groupBy(
    cfdis: CfdiInterface[],
    key: string
  ): { [key: string]: CfdiInterface[] } {
    const groups: { [key: string]: CfdiInterface[] } = {};
    cfdis.forEach((cfdi) => {
      const groupKey: string = cfdi[key];
      if (!groups[groupKey]) {
        groups[groupKey] = [cfdi];
        return;
      }

      groups[groupKey].push(cfdi);
    });

    return groups;
  }

  filterCfdis(cfdis: CfdiInterface[]): CfdiInterface[] {
    return cfdis.filter(
      (cfdi: CfdiInterface) =>
        (cfdi.estatus === "Aprobado" ||
          cfdi.estatus === "Error de cancelación") &&
        cfdi.uuid.length > 0
    );
  }

  getindexExtra(key: string, list: Array<any>): number {
    return list.findIndex((item) => item.backNombre === key);
  }
}
