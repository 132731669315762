import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TemaService } from '../../services/tema.service';

@Component({
  selector: 'app-msg-xml-pdf',
  templateUrl: './msg-xml-pdf.component.html',
  styleUrls: ['./msg-xml-pdf.component.css']
})
export class MsgXmlPdfComponent implements OnInit {

  constructor(
    /*=====Servicios=====*/
    public _matDialogRef: MatDialogRef<MsgXmlPdfComponent>,
    public _tema: TemaService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    /*=====Servicios=====*/
  ) { }

  ngOnInit() {
  }

  cancelar() {
    this._matDialogRef.close();
  }
}
