import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TemaService } from '../../services/tema.service';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  msg = 'error';

  constructor(
    /*=====Servicios=====*/
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialogRef: MatDialogRef<ConfirmacionComponent>,
    public _tema: TemaService
    /*=====Servicios=====*/) {
  }

  ngOnInit() {
    this.msg = this.data.msg;
  }

  onAceptar() {
    this._matDialogRef.close('Aceptar');
  }
  onCancelar() {
    this._matDialogRef.close('Cancelar');
  }
}
