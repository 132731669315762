import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService, PermisosService, TemaService, LoadingService } from '../../../index.services';
/*modelos llenado */
import { CfdiInterface, OpMes, OpYear } from '../modelos/cfdi3.3';
/*momentJS*/
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { LoadingComponent } from '../../../modals/loading/loading.component';
import { MsgXmlPdfComponent } from '../../../modals/msg-xml-pdf/msg-xml-pdf.component';
import { Message } from 'primeng/api';
import { setCfdiStatus } from '../cfdi-status';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css']
})
export class BasicComponent implements OnInit {
  @Output() eventCerrarInfo = new EventEmitter();
  /*formulario de la busqueda basica */
  formBusquedaBas: FormGroup;
  /*bandera de error en la conexion */
  msgErrorBas = '';
  /*selccion de mes (basico)*/
  selMes = new OpMes().lista;
  /*selccion de año (basico)*/
  selYear = new OpYear();
  msgs: Message[] = [];
  @ViewChild('anchorDownload') anchorDownload: ElementRef;

  comprobantesBasico: CfdiInterface[] = new Array();


  opTotalPag = [250, 500, 1000];
  totalDatosSel = this.opTotalPag[0];
  totalDatos = 0;
  limit = 250;
  paginaActual = 1;

  isloading = false;

  constructor(
    /*=====Servicios=====*/
    public _matDialog: MatDialog,
    public _httpService: HttpService,
    public _permisosService: PermisosService,
    private _loadingService: LoadingService,
    public _temaService: TemaService
    /*=====Servicios=====*/
  ) {
    const dateNow = new Date();
    this.formBusquedaBas = new FormGroup({
      mes: new FormControl((dateNow.getMonth() + 1) + '', [Validators.required]),
      year: new FormControl(dateNow.getFullYear(), [Validators.required])
    });
  }

  ngOnInit() {
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para buscar comprobantes con el boton buscar de la pantalla 'basico' ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  buscarBasic(offset: number = 0, pActual: number = 1) {
    if (this.formBusquedaBas.valid) {
      this.paginaActual = pActual;

      this.msgErrorBas = 'carga';
      const temp1: Date = new Date(this.formBusquedaBas.controls['mes'].value + '/01' + '/' + this.formBusquedaBas.controls['year'].value);
      temp1.setHours(0, 0, 0);
      const temp2: Date = new Date(this.formBusquedaBas.controls['mes'].value + '/01' + '/' + this.formBusquedaBas.controls['year'].value);
      temp2.setHours(23, 59, 59);
      temp2.setMonth(temp2.getMonth() + 1);
      temp2.setDate(temp2.getDate() - 1);

      const busqueda = { 'date-Initial': temp1.toISOString(), 'date-End': temp2.toISOString() };

      this.comprobantesBasico = [];
      this.isloading = true;
      this._httpService.getSearchTransactionsBas(busqueda, { limit: this.limit, offset: offset }).subscribe(
        resp => {
          this.totalDatos = resp['total_count'];
          this.comprobantesBasico = this.llenatTabla(resp['transactions']);
          this.msgErrorBas = 'sinReg';
          this.isloading = false;
        },
        err => {
          this.msgErrorBas = 'Error';
          this.isloading = false;
        }
      );
    } else {
      this.formBusquedaBas.controls['mes'].markAsTouched();
      this.formBusquedaBas.controls['year'].markAsTouched();
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar la tabla con la informacion del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  llenatTabla(data) {
    const cfdiArry: CfdiInterface[] = new Array();
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const cfdiDat: CfdiInterface = {
        id: null,
        estatus: '',
        serie: '',
        folio: null,
        version: '',
        rfcEmisor: '',
        nombreEmisor: '',
        rfcReceptor: '',
        nombreReceptor: '',
        folioReferencia: '',
        paypalID: '',
        // fechaEmision: '',
        fechaCreacion: '',
        total: null,
        moneda: '',
        uuid: '',
        detalle: '',
        extra1: '',
        extra2: '',
        extra3: '',
        extra4: '',
        extra5: '',
        extra6: '',
        extra7: '',
        extra8: '',
        extra9: '',
        extra10: ''
      };
      cfdiDat.id = element.id;
      cfdiDat.estatus = setCfdiStatus(element.stamp_status);

      // cfdiDat.fechaEmision = this.formatoFecha(element.timestamp, 'll');
      cfdiDat.fechaCreacion = this.formatoFecha(element.timestamp, 'll');
      // cfdiDat.rfcEmisor = element.sender;
      // cfdiDat.rfcEmisor = element.cfdi.sender_rfc;
      cfdiDat.rfcEmisor = 'OPM150323DI1';
      cfdiDat.folioReferencia = 'xdxdxddd';

      if (element.last_err) {
        cfdiDat.detalle = element.last_err;
      }
      if (element.cfdi) {
        const cfdi = element.cfdi;
        cfdiDat.moneda = cfdi.coin;
        // cfdiDat.fechaCreacion = this.formatoFecha(cfdi.emision_date, 'll');
        // cfdiDat.fechaEmision = this.formatoFecha(cfdi.emision_date, 'll');
        cfdiDat.folio = cfdi.folio;
        cfdiDat.nombreReceptor = cfdi.receptor_name;
        cfdiDat.rfcReceptor = cfdi.receptor_rfc;
        cfdiDat.nombreEmisor = cfdi.sender_name;
        // cfdiDat.rfcEmisor = cfdi.sender_rfc;
        cfdiDat.paypalID = cfdi.paypalID;
        cfdiDat.serie = cfdi.series;
        cfdiDat.total = cfdi.total;
        cfdiDat.uuid = cfdi.uuid;
        cfdiDat.version = cfdi.version;
        cfdiDat.extra1 = cfdi.extra1;
        cfdiDat.extra2 = cfdi.extra2;
        cfdiDat.extra3 = cfdi.extra3;
        cfdiDat.extra4 = cfdi.extra4;
        cfdiDat.extra5 = cfdi.extra5;
        cfdiDat.extra6 = cfdi.extra6;
        cfdiDat.extra7 = cfdi.extra7;
        cfdiDat.extra8 = cfdi.extra8;
        cfdiDat.extra9 = cfdi.extra9;
        cfdiDat.extra10 = cfdi.extra10;
        cfdiDat.folioReferencia = cfdi.folio_referencia;
      }
      cfdiArry.push(cfdiDat);
    }
    return cfdiArry;
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para dar formatato a las fechas ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  formatoFecha(fecha: Date, formato: string) {
    return moment(fecha).locale('es').format(formato);
  }
  cerrarInfo() {
    this.eventCerrarInfo.emit();
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar EL PDF del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getPdf(seleccion: CfdiInterface[], open: boolean) {
    /*ABRIR solo un pdf */
    if (seleccion.length === 1 && open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(-1);
        this._httpService.getPDF(seleccion[0].id).subscribe(
          (resp: { data: Blob, extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, 'pdf', resp.extension);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG('Error al obtener el archivo');
          }
        );
      } else {
        this.mostrarMSG('El documento PDF de este CFDI no está disponible');
      }
      /*DESCARGAR solo un pdf */
    } else if (seleccion.length === 1 && !open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(1, 5948);
        this._httpService.getPDF(seleccion[0].id).subscribe(
          (resp: { data: Blob, extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, 'pdf', resp.extension);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG('Error al obtener el archivo');
          }
        );
      } else {
        this.mostrarMSG('El documento PDF de este CFDI no está disponible');
      }
    }
  }
  opneModalLoading(totalFiles: number, avgSize?: number) {
    let total = -1;
    if (avgSize) {
      total = totalFiles * avgSize;
    }
    const modal = this._matDialog.open(LoadingComponent, {
      panelClass: 'loading-modal',
      disableClose: true,
      // backdropClass: 'cortina-loading-modal',
      data: { total: total }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar/ver el archivo que manda el back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getFile(info, open: boolean, tipo: string, nombre: string) {
    const newBlob = new Blob([info], { type: 'application/' + tipo });
    const data = window.URL.createObjectURL(newBlob);
    if (open) {
      window.open(data);
    } else {
      this.anchorDownload.nativeElement.href = data;
      this.anchorDownload.nativeElement.download = nombre;
      this.anchorDownload.nativeElement.click();
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*===metodo para mostrar el mensaje de informacion (msg superior derecha) ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  showMSG(mensaje: string, color?: string, titulo?: string, ) {
    let icono = 'error';
    if (!color && !titulo) {
      titulo = 'ERROR!';
    } else {
      switch (color) {
        case 'blue':
          icono = 'info';
          break;
        case 'green':
          icono = 'success';
          break;

        default:
          icono = 'error';
          break;
      }
    }
    this.msgs = [];
    this.msgs.push({ severity: icono, summary: titulo, detail: mensaje });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostrar una mensaje modal===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  mostrarMSG(msg: string) {
    const modal = this._matDialog.open(MsgXmlPdfComponent, {
      minWidth: '250px',
      data: { msg: msg }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar eL xml del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getXml(seleccion: CfdiInterface[], open: boolean) {
    if (seleccion.length === 1 && open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(-1);
        this._httpService.getXML(seleccion[0].id).subscribe(
          (resp: { data: Blob, extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, 'xml', resp.extension);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG('Error al obtener el archivo');
          }
        );
      } else {
        this.mostrarMSG('El documento XML de este CFDI no está disponible');
      }
    } else if (seleccion.length === 1 && !open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(1, 6770);
        this._httpService.getXML(seleccion[0].id).subscribe(
          (resp: { data: Blob, extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, 'xml', resp.extension);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG('Error al obtener el archivo');
          }
        );
      } else {
        this.mostrarMSG('El documento XML de este CFDI no está disponible');
      }
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para pedir una nueva pagina (detondao por el componente
   'app-pages') ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onChangePage(nextPage: { limit: number, offset: number, numPagActual: number, selTotalPag: number }) {
    this.totalDatosSel = nextPage.selTotalPag;
    this.limit = nextPage.limit;
    this.buscarBasic(nextPage.offset, nextPage.numPagActual);
  }
}
