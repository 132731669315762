import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TemaService } from '../../index.services';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {
  detalle: string;

  constructor(
    /*=====Servicios=====*/
    public _matDialogRef: MatDialogRef<DetalleComponent>,
    public _tema: TemaService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    /*=====Servicios=====*/
  ) { }

  ngOnInit() {
    if (this.data.detalle.length > 0) {
      this.detalle = this.data.detalle;
    } else {
      this.detalle = 'Transacción correcta';
    }
  }
  cancelar() {
    this._matDialogRef.close();
  }
}
