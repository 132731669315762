import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';

@Component({
  selector: 'app-solicitud-timbre',
  templateUrl: './solicitud-timbre.component.html',
  styleUrls: ['./solicitud-timbre.component.css']
})
export class SolicitudTimbreComponent implements OnInit {

  constructor(public _tema: TemaService) { }

  ngOnInit() {
  }

}
