import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

// interceptor
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MyInterceptor } from "./myInterceptor";

// modulos creados
import { PrimengModule } from "./modules/primeng.module";
import { MaterialModule } from "./modules/material.module";
// formularios
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// ruteo
import { appRouting } from "./app.routes";

import { AppComponent } from "./app.component";

import { MAT_DATE_LOCALE } from "@angular/material";

// SlideshowModule
import { SlideshowModule } from "ng-simple-slideshow";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavbarComponent } from "./menus/navbar/navbar.component";
// import { LoginComponent } from './modals/login/login.component';
import { HomeComponent } from "./components/home/home.component";
import { EmpresasEmisorasComponent } from "./components/empresas-emisoras/empresas-emisoras.component";
import { UsersComponent } from "./components/users/users.component";
import { DestinatariosZipComponent } from "./components/destinatarios-zip/destinatarios-zip.component";
import { CertificadosComponent } from "./components/certificados/certificados.component";
import { TimbresFiscalesComponent } from "./components/timbres-fiscales/timbres-fiscales.component";
import { ComprobantesComponent } from "./components/comprobantes/comprobantes.component";
import { AuditoriasComponent } from "./components/auditorias/auditorias.component";
import { CargaLayoutComponent } from "./components/carga-layout/carga-layout.component";
import { VerEstadisticasComponent } from "./components/ver-estadisticas/ver-estadisticas.component";
import { EmpresaComponent } from "./modals/empresa/empresa.component";
import { LogotiposComponent } from "./modals/logotipos/logotipos.component";
import { AddUserComponent } from "./modals/add-user/add-user.component";
import { EditUserComponent } from "./modals/edit-user/edit-user.component";
import { CambiarPassComponent } from "./modals/cambiar-pass/cambiar-pass.component";
import { FiltrosPermisosComponent } from "./modals/filtros-permisos/filtros-permisos.component";
import { DestinatarioComponent } from "./modals/destinatario/destinatario.component";
import { AddCertificadoComponent } from "./modals/add-certificado/add-certificado.component";
import { AddTimbreComponent } from "./modals/add-timbre/add-timbre.component";
import { VerTimbradorComponent } from "./modals/ver-timbrador/ver-timbrador.component";
import { SolicitudTimbreComponent } from "./modals/solicitud-timbre/solicitud-timbre.component";
import { MessageComponent } from "./modals/message/message.component";
import { IdentidadComponent } from "./modals/identidad/identidad.component";
import { FileuploadComponent } from "./elements/fileupload/fileupload.component";
import { ManagerComponent } from "./components/manager.component";
import { LoginComponent } from "./login/login.component";
import { EditarEmpresaComponent } from "./modals/editar-empresa/editar-empresa.component";
import { ComprobanteResposiveComponent } from "./elements/comprobante-resposive/comprobante-resposive.component";
import { LoadinTablesComponent } from "./elements/loadin-tables/loadin-tables.component";
import { ConfirmacionComponent } from "./modals/confirmacion/confirmacion.component";
import { PagesComponent } from "./elements/pages/pages.component";
import { ReenvioCorroComponent } from "./modals/reenvio-corro/reenvio-corro.component";
import { DetalleComponent } from "./modals/detalle/detalle.component";
import { InfoComponent } from "./modals/info/info.component";
import { MsgXmlPdfComponent } from "./modals/msg-xml-pdf/msg-xml-pdf.component";
import { LoadingComponent } from "./modals/loading/loading.component";
import { BasicComponent } from "./components/comprobantes/basic/basic.component";
import { AdvancedComponent } from "./components/comprobantes/advanced/advanced.component";
import { ExtrasComponent } from "./components/extras/extras.component";
import { PaypalLoginComponent } from "./paypal-login/paypal-login.component";
import { PaypalValidationComponent } from "./paypal-login/paypal-validation/paypal-validation.component";
import { EditUserPaypalComponent } from "./modals/edit-user-paypal/edit-user-paypal.component";
import { NotificationComponent } from "./modals/notification/notification.component";
import { LoginAdminComponent } from "./modals/login-admin/login-admin.component";
import { DownloadFilesComponent } from "./paypal-download/download-files/download-files.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { OpenConfigComponent } from "./components/open-config/open-config.component";
import { ReporteAuxComponent } from "./modals/reporte-aux/reporte-aux.component";
import { CancellationTableComponent } from "./modals/cancellation-table/cancellation-table.component";
import { BusinessNameNotificationComponent } from "./modals/business-name-notification/business-name-notification.component";
import { ConfigurableNotificationComponent } from "./services/notifications/configurable-notification/configurable-notification.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    HomeComponent,
    EmpresasEmisorasComponent,
    UsersComponent,
    DestinatariosZipComponent,
    CertificadosComponent,
    TimbresFiscalesComponent,
    ComprobantesComponent,
    AuditoriasComponent,
    CargaLayoutComponent,
    VerEstadisticasComponent,
    EmpresaComponent,
    LogotiposComponent,
    AddUserComponent,
    EditUserComponent,
    CambiarPassComponent,
    FiltrosPermisosComponent,
    DestinatarioComponent,
    AddCertificadoComponent,
    AddTimbreComponent,
    VerTimbradorComponent,
    SolicitudTimbreComponent,
    MessageComponent,
    IdentidadComponent,
    FileuploadComponent,
    ManagerComponent,
    EditarEmpresaComponent,
    ComprobanteResposiveComponent,
    LoadinTablesComponent,
    ConfirmacionComponent,
    PagesComponent,
    ReenvioCorroComponent,
    DetalleComponent,
    InfoComponent,
    MsgXmlPdfComponent,
    LoadingComponent,
    BasicComponent,
    AdvancedComponent,
    ExtrasComponent,
    PaypalLoginComponent,
    PaypalValidationComponent,
    EditUserPaypalComponent,
    NotificationComponent,
    LoginAdminComponent,
    DownloadFilesComponent,
    PageNotFoundComponent,
    OpenConfigComponent,
    ReporteAuxComponent,
    CancellationTableComponent,
    BusinessNameNotificationComponent,
    ConfigurableNotificationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // modulos creados
    PrimengModule,
    MaterialModule,
    SlideshowModule,

    appRouting,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
  ],
  entryComponents: [
    EmpresaComponent,
    LogotiposComponent,
    AddUserComponent,
    EditUserComponent,
    CambiarPassComponent,
    FiltrosPermisosComponent,
    DestinatarioComponent,
    AddCertificadoComponent,
    AddTimbreComponent,
    VerTimbradorComponent,
    SolicitudTimbreComponent,
    MessageComponent,
    IdentidadComponent,
    EditarEmpresaComponent,
    ConfirmacionComponent,
    ReenvioCorroComponent,
    DetalleComponent,
    InfoComponent,
    MsgXmlPdfComponent,
    LoadingComponent,
    EditUserPaypalComponent,
    NotificationComponent,
    LoginAdminComponent,
    ReporteAuxComponent,
    CancellationTableComponent,
    BusinessNameNotificationComponent,
    ConfigurableNotificationComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
