import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Message } from 'primeng/api';
import { MatDialog } from '@angular/material';
import { AddCertificadoComponent } from '../../modals/add-certificado/add-certificado.component';

interface Certificado {
  rfc: string;
  noCertificado: number;
  fechaInicio: Date;
  fechaFin: Date;
  estado: string;
}

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.css']
})
export class CertificadosComponent implements OnInit {
  msgs: Message[] = [];
  opciones: MenuItem[];
  certificados: Certificado[];
  selccion: Certificado;

  constructor(public _matDialog: MatDialog) {
    this.certificados = [
      {
        rfc: 'GVD975485682DSD1',
        noCertificado: 64329,
        fechaInicio: new Date('8/18/2018'),
        fechaFin: new Date('8/19/2019'),
        estado: 'Activo'
      },
      {
        rfc: 'FYGHD564HGCF54',
        noCertificado: 71815,
        fechaInicio: new Date('7/2/2017'),
        fechaFin: new Date('7/5/2018'),
        estado: 'Revocado'
      }
    ];
    this.opciones = [
      {
        label: 'Eliminar', icon: ' icon-cross2',
        command: (eve) => {
          this.eliminar();
        }
      }
    ];

  }

  ngOnInit() {
  }

  openAddCertificado() {
    const modalEmp = this._matDialog.open(AddCertificadoComponent,
      {
        width: '1000px'
      }
    );
  }

  eliminar() {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'Certificado Eliminada' });
  }

}
