export const CFDI_STATUS: { [key: string]: string } = {
  APPROVED: "Aprobado",
  ERROR_TIMBRE: "Error de Timbrado",
  PROCESSING: "Procesando...",
  CANCEL_REQUESTED: "Cancelación Solicitada",
  CANCELED: "Cancelado",
  CANCELATING: "Cancelando...",
  ERROR_CANCEL: "Error de Cancelación",
};

export function setCfdiStatus(status: string): string {
  const translatedStatus = CFDI_STATUS[status];
  return translatedStatus ? translatedStatus : status+'*';
}
