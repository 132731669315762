import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate, CanActivateChild } from '@angular/router';
import { TemaService } from './tema.service';
import { PermisosService } from './permisos.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  // activo = false;

  constructor(
    /*=====Servicios=====*/
    // private _router: Router,
    private _loginService: LoginService,
    public _tema: TemaService,
    private _permisosService: PermisosService
    /*=====Servicios=====*/
  ) { }
  canActivate(next: ActivatedRouteSnapshot) {
    if (localStorage.getItem('usuario')) {
      this._tema.getLocalTema();
      return true;
    } else {
      this._loginService.logout();
      return false;
    }
  }
  canActivateChild(next: ActivatedRouteSnapshot) {
    const ruta = next.routeConfig.path;
    if (ruta === 'empresas_emisoras' ||
      ruta === 'usuarios' ||
      ruta === 'certificados' ||
      ruta === 'timbres_fiscales') {
      if (this._permisosService.getGuardPermiso(ruta)) {
        return this.canActivate(next);
      } else {
        this._loginService.logout();
        return false;
      }
    }
    return this.canActivate(next);
  }

}
