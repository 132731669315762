import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatChipInputEvent, MAT_DIALOG_DATA } from '@angular/material';
import { TemaService } from '../../index.services';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { HttpService } from '../../index.services';

@Component({
  selector: 'app-reenvio-corro',
  templateUrl: './reenvio-corro.component.html',
  styleUrls: ['./reenvio-corro.component.css']
})
export class ReenvioCorroComponent implements OnInit {
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  asunto = new FormControl();
  mails: { dir: string, valid: boolean }[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  mailsValid = false;
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    private _httpService: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public _matDialogRef: MatDialogRef<ReenvioCorroComponent>,
    public _tema: TemaService
    /*=====Servicios=====*/
  ) {
  }

  ngOnInit() {
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this._matDialogRef.close();
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal enviando la informacion ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onEnviar() {
    if (this.mailsValid) {
      const dirMails: String[] = [];
      for (let index = 0; index < this.mails.length; index++) {
        const element = this.mails[index];
        dirMails.push(element.dir);
      }
      const temp = {
        'emails': dirMails,
        'message': this.asunto.value
      };
      this._httpService.postMail(this.data.id, temp).subscribe(
        () => {
          this._matDialogRef.close(0);
        },
        () => {
          this._matDialogRef.close(1);
        }
      );
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // tslint:disable-next-line:max-line-length
    const patern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = patern.test(String(value).toLowerCase());

    if ((value || '').trim()) {
      this.mails.push({ dir: value, valid: valid });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.mailsValid = this.upDateValid(this.mails);
  }

  remove(mail): void {
    const index = this.mails.indexOf(mail);
    if (index >= 0) {
      this.mails.splice(index, 1);
    }
    this.mailsValid = this.upDateValid(this.mails);
  }
  upDateValid(correos): boolean {
    if (correos.length > 0) {
      for (let index = 0; index < correos.length; index++) {
        const element = correos[index];
        if (!element.valid) {
          return false;
        }
      }
      return true;
    }
    return false;
  }



}
