export const url = 'https://desa-api.paypal.detecno.com';

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*=== url paypal ===*/
/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const configBtn = `
paypal.use( ['login'], function (login) {
  login.render ({
     "appid":"AcDzItbiaxiQnJ5ISzNgiBY0OUBiIsDhiHTa-Gf3jZhQE0UhyDA9g1q4GT8VECr8X6jzP_1v-0p2rQ0p",
     "authend":"sandbox",
     "scopes":"openid profile email address https://uri.paypal.com/services/paypalattributes",
     "containerid":"cwppButton",
     "responseType":"code",
     "locale":"en-us",
     "buttonType":"CWP",
     "buttonShape":"pill",
     "buttonSize":"lg",
     "fullPage":"true",
     "returnurl":"https://desa-paypal.detecno.com/payPal"
  });
});`;
