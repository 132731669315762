import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Observable } from "rxjs";
import {
  ButtonsConfiguration,
  ConfigurableNotificationComponent,
  NotificationConfigData,
} from "./configurable-notification/configurable-notification.component";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    /* servicios <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
    public _matDialog: MatDialog
  ) {}
  /*  getModalConfig XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * method to obtain the standard modal configuration
   */
  private getModalConfig(data: NotificationConfigData): MatDialogConfig {
    return { data };
  }
  /* openNotification XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * method to open a simple notification modal
   * @param message notification message
   * @param buttonLabel button label ("Aceptar")
   * @returns `afterClosed<void>`
   */
  openNotification(message: string, buttonLabel = "Aceptar"): Observable<void> {
    return this._matDialog
      .open(
        ConfigurableNotificationComponent,
        this.getModalConfig({
          message,
          buttonsConfig: buttonLabel,
        })
      )
      .afterClosed();
  }
  /* openConfirmation XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * method to open a confirmation modal
   * @param message notification message
   * @param buttonLabel buttons labels ("Aceptar","Cancelar")
   * @returns `afterClosed<boolean>`
   */
  openConfirmation(
    message: string,
    buttonsConfig: ButtonsConfiguration = {
      okBtnLabel: "Aceptar",
      noBtnLabel: "Cancelar",
    }
  ): Observable<void> {
    return this._matDialog
      .open(
        ConfigurableNotificationComponent,
        this.getModalConfig({
          message,
          buttonsConfig,
        })
      )
      .afterClosed();
  }
}
