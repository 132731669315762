import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';

import { MatDialogRef, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpService } from '../../index.services';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*Formulario de Datos Generales (Paso 1)*/
  formStep1: FormGroup;
  /* Formulario de permisos y Filtros (Paso 2) */
  formStep2: FormGroup;
  /*bandera auxiliar para el paso3 en la lista de permisos */
  checked = true;
  /*lista de enpresas disponibles para selccionar */
  listaEmpresas: string[] = new Array();
  /*lista de RFC receptores seleccionados */
  rfcReceptor: any[] = [];
  /*lista de separadores el RFC emisor */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  verErrorlistaEmpresas = false;

  checkComprobantes = [true, true];

  checkPermisos = [true, true];

  // permisos = [
  //   'Administrar Usuarios',
  //   'Administrar Empresas',
  //   'Configuracion SAT',
  //   // 'Subir CERT para las empresas',
  //   // 'generar PDF/XML',
  //   // 'Descargar PDF',
  //   // 'Descargar XML',
  //   // 'Estadisticas',
  //   'Cancelar Documentos',
  //   // 'Buscar Retenciones',
  //   // 'Ver PDF',
  //   // 'Ver XML',
  //   'Envio De Comprobante Por Correo'
  // ];



  permisos: { label: string, tip: string }[] = [
    { label: 'Administrar usuarios', tip: 'Permiso para poder agregar, editar o eliminar usuarios' },
    { label: 'Administrar empresas', tip: 'Permiso para poder agregar, editar o eliminar empresas' },
    { label: 'Configuración SAT', tip: 'Permiso para poder realizar las configuraciones de SAT' },
    { label: 'Cancelar documentos', tip: 'Permiso para poder cancelar un CFDI' },
    { label: 'Envío de comprobante por correo', tip: 'Permiso para poder enviar un correo con el CFDI desde comprobantes' }
  ];

  msgPermisos = true;
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    private _httpService: HttpService,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<AddUserComponent>
    /*=====Servicios=====*/
  ) {
    /*controladores para el Formulario de Datos Generales (Paso 1) */
    this.formStep1 = new FormGroup({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      pass: new FormControl('', [Validators.required]),
      repPass: new FormControl(''),
      email: new FormControl('', [Validators.required]
      ),
    });
    this.formStep1.controls['repPass'].setValidators([Validators.required, this.pasIgual.bind(this.formStep1)]);
    /*controladores para el Formulario de Permisos y Filtros (Paso 2) */
    this.formStep2 = new FormGroup({
      rol: new FormControl('', [Validators.required]),
      empresaEmi: new FormArray([]),
      limitarRFC: new FormControl(false),
      receptorRFC: new FormControl(''),
      todosPermisos: new FormControl(false),
      permisos: new FormArray([])
    });
    for (let element = 0; element < this.permisos.length; element++) {
      (<FormArray>this.formStep2.controls['permisos']).push(
        new FormControl(false)
      );
    }

    /*llenado de la lista de empresas disponibles */
    const userID = JSON.parse(localStorage.getItem('usuario')).id;
    _httpService.getUser(userID).subscribe(
      resp => {
        this.listaEmpresas = resp['company_rfcs'];
        this.formStep2.controls['empresaEmi'].setErrors({ incorrect: true });
        for (let emp = 0; emp < this.listaEmpresas.length; emp++) {
          (<FormArray>this.formStep2.controls['empresaEmi']).push(
            new FormControl({ value: false, disabled: true })
          );
        }
      },
      () => { }
    );
  }

  ngOnInit() {
    this.formStep2.controls['empresaEmi'].valueChanges.subscribe(val => {
      for (let i = 0; i < val.length; i++) {
        this.formStep2.controls['empresaEmi'].setErrors({ incorrect: true });
        if (val[i]) {

          this.formStep2.controls['empresaEmi'].setErrors(null);
          break;
        }
      }
    });

    this.formStep1.controls['pass'].valueChanges.subscribe(() => {
      this.formStep1.controls['repPass'].updateValueAndValidity();
    });

    /*si selcciona rol de administrador ('0') habilitar y limpiar, o deshabilitar y llenar
     el componente de selccion de empresa */
    this.formStep2.controls['rol'].valueChanges.subscribe(resp => {
      if (resp === 'admin') {
        this.llenarEmpEmi(true);
        this.formStep2.controls['todosPermisos'].setValue(true);
        this.formStep2.controls['todosPermisos'].disable();
        this.formStep2.controls['permisos'].disable();
      } else {
        this.llenarEmpEmi(false);
        this.formStep2.controls['todosPermisos'].setValue(false);
        this.formStep2.controls['todosPermisos'].enable();
        this.formStep2.controls['permisos'].enable();
      }
    });





    /*asignar o quitar el validador del componente de lista de receptores de RFC segun
    si activa el limitarRFC */
    this.formStep2.controls['limitarRFC'].valueChanges.subscribe(resp => {
      if (resp) {
        this.formStep2.controls['receptorRFC'].setValidators([Validators.required]);
        if (this.rfcReceptor.length < 1) {
          this.formStep2.controls['receptorRFC'].setErrors({ 'incorrect': true });
        }
      } else {
        this.formStep2.controls['receptorRFC'].clearValidators();
        this.formStep2.controls['receptorRFC'].setErrors(null);
        this.rfcReceptor = [];
      }
    });

    this.formStep2.controls['receptorRFC'].valueChanges.subscribe(resp => {
      if (resp.length < 1 && this.rfcReceptor.length > 0) {
        this.formStep2.controls['receptorRFC'].setErrors(null);
      }
    });

    this.formStep2.controls['todosPermisos'].valueChanges.subscribe(val => {
      if (this.checkPermisos[0]) {
        this.checkPermisos[1] = false;
        for (let i = 0; i < (<FormArray>this.formStep2.controls['permisos']).length; i++) {
          (<FormArray>this.formStep2.controls['permisos']).controls[i].setValue(val);
        }
        this.checkPermisos[1] = true;
      }
    });

    this.formStep2.controls['permisos'].valueChanges.subscribe(val => {
      if (this.checkPermisos[1]) {
        this.checkPermisos[0] = false;
        for (let i = 0; i < val.length; i++) {
          if (!val[i]) {
            this.formStep2.controls['todosPermisos'].setValue(false);
            break;
          } else {
            this.formStep2.controls['todosPermisos'].setValue(true);
          }
        }
        this.checkPermisos[0] = true;
      }
      for (let i = 0; i < val.length; i++) {
        if (val[i]) {
          this.msgPermisos = false;
          break;
        }
        this.msgPermisos = true;
      }
    });
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar todas la empresas
  emisoras  ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  private llenarEmpEmi(val: boolean) {
    for (let i = 0; i < (<FormArray>this.formStep2.controls['empresaEmi']).length; i++) {
      (<FormArray>this.formStep2.controls['empresaEmi']).controls[i].setValue(val);
      if (val) {
        (<FormArray>this.formStep2.controls['empresaEmi']).controls[i].disable();
      } else {
        (<FormArray>this.formStep2.controls['empresaEmi']).controls[i].enable();
      }
    }
  }

  public getEmpEmi(): string[] {
    const r: string[] = new Array();
    for (let i = 0; i < (<FormArray>this.formStep2.controls['empresaEmi']).length; i++) {

      const isCheked = (<FormArray>this.formStep2.controls['empresaEmi']).controls[i].value;
      if (isCheked) {
        r.push(this.listaEmpresas[i]);
      }
    }
    return r;
  }

  private getPermisos(perSel): string[] {
    const temp = [];
    for (let index = 0; index < perSel.length; index++) {
      const element = perSel[index];
      if (element) {
        switch (index) {
          case 0:
            temp.push('users.crud');
            break;
          case 1:
            temp.push('company.crud');
            break;
          case 2:
            temp.push('cert.upload');
            break;
          // case 3:
          //   temp.push('cfdi.files');
          //   break;
          case 3:
            temp.push('cfdi.cancel');
            break;
          case 4:
            temp.push('cfdi.mail');
            break;
        }
      }
    }
    temp.push('cfdi.files');
    return temp;
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para guardar la informacion del
  usuario ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  guardar() {
    let rfcEmi = new Array();
    if (this.formStep2.controls['rol'].value === 'admin') {
      rfcEmi = this.listaEmpresas;
    } else {
      // rfcEmi = this.formStep2.controls['empresaEmi'].value;
      rfcEmi = this.getEmpEmi();
    }
    const informacion = {
      name: this.formStep1.controls['name'].value,
      last_name: this.formStep1.controls['last_name'].value,
      pass: this.formStep1.controls['pass'].value,
      email: this.formStep1.controls['email'].value,
      company_rfcs: rfcEmi,
      receptor_rfcs: this.rfcReceptor,
      permissions: this.getPermisos(this.formStep2.controls['permisos'].value),
      // permissions: ['users.crud'],
      type: this.formStep2.controls['rol'].value
    };

    this._httpService.postUser(informacion).subscribe(
      () => {
        this.dialogRef.close('ok');
      },
      () => {
        this.dialogRef.close('error');
      }
    );

  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close(0);
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para quitar empresa de la lista de
  refReceptores ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  remove(rfc): void {
    const index = this.rfcReceptor.indexOf(rfc);
    if (index >= 0) {
      this.rfcReceptor.splice(index, 1);
    }
    if (this.rfcReceptor.length < 1) {
      this.formStep2.controls['receptorRFC'].setErrors({ 'incorrect': true });
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para agregar empresa de la lista
  de refReceptores ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.rfcReceptor.push(value);
      this.formStep2.controls['receptorRFC'].setErrors(null);
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*===validacion personalizada para verificar
  contraseñas iguales ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  pasIgual(pass: FormControl): { [s: string]: boolean } {
    const repPass: any = this;
    if (pass.value !== repPass.controls['pass'].value) {
      return { noiguales: true };
    }
    return null;
  }

  get FC_empresaEmi() {
    return (<FormArray>this.formStep2.controls['empresaEmi']).controls;
  }
  get FC_permisos() {
    return (<FormArray>this.formStep2.controls['permisos']).controls;
  }
}
