import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadGuard implements CanActivate {
  constructor(
    /*=========================Servicios=========================*/
    private _loginService: LoginService,
    /*=========================Servicios=========================*/
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const myParams = next.queryParams;
    const user = localStorage.getItem('usuario');

    if (myParams.hasOwnProperty('id') && myParams.hasOwnProperty('tipo')) {
      localStorage.setItem('downloadData', JSON.stringify(myParams));
    }

    if (user) {
      return true;
    } else {
      this._loginService.logout();
      return false;
    }
  }
}
