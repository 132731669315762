import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';

@Component({
  selector: 'app-destinatario',
  templateUrl: './destinatario.component.html',
  styleUrls: ['./destinatario.component.css']
})
export class DestinatarioComponent implements OnInit {

  constructor(public _tema: TemaService) { }

  ngOnInit() {
  }

}
