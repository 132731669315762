import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  info = {
    imgUrl: 'lightcoral',
    msg: 'ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! '
  };

  constructor() {
    const randSelc = Math.floor(Math.random() * 3);
    this.llenarInfo(randSelc);
  }

  ngOnInit() {
  }

  llenarInfo(key) {
    switch (key) {
      case 0:
        this.info = {
          imgUrl: '../../../assets/img/Pop_1.jpg',
          msg: ``
        };
        break;
      // case 1:
      //   this.info = {
      //     imgUrl: 'url(../../../assets/img/Pop_2.png)',
      //     msg: ``
      //   };
      //   break;
      default:
        this.info = {
          imgUrl: '../../../assets/img/Pop_3.png',
          msg: ``
        };
        break;
    }
  }

}
