import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carga-layout',
  templateUrl: './carga-layout.component.html',
  styleUrls: ['./carga-layout.component.css']
})
export class CargaLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
