import { Injectable } from '@angular/core';
import { epLogin } from './endpoint';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginPaypalService {
  constructor(
    /*=========================Servicios=========================*/
    private _HttpClient: HttpClient
    /*=========================Servicios=========================*/
  ) {
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo el login con paypal ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  loginPaypal(code) {
    const url = epLogin.login + '/paypal?code=' + code;
    return this._HttpClient.get(url);
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para registar un usuario nuevo de paypal ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  signInPaypal(newUser: {
    token: string
    receptor_rfc: string
    business_name: string
    past_cfdis: string
  }) {
    const url = epLogin.login + '/paypal-user';
    return this._HttpClient.post(url, newUser);
  }
}
