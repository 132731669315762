import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { Message } from 'primeng/api';
import { MatDialog } from '@angular/material';
import { DestinatarioComponent } from '../../modals/destinatario/destinatario.component';

interface Destinataro {
  id: number;
  rfcDestinatario: string;
  nombreDestinatario: string;
}

@Component({
  selector: 'app-destinatarios-zip',
  templateUrl: './destinatarios-zip.component.html',
  styleUrls: ['./destinatarios-zip.component.css']
})
export class DestinatariosZipComponent implements OnInit {
  msgs: Message[] = [];

  opciones: MenuItem[];
  destinatarios: Destinataro[];
  selccion: Destinataro;

  constructor(public _matDialog: MatDialog) {
    this.destinatarios = [
      {
        id: 8742,
        rfcDestinatario: 'H56746G52',
        nombreDestinatario: 'Keven'
      },
      {
        id: 63183,
        rfcDestinatario: 'GHD54564HJBG',
        nombreDestinatario: 'Freddy'
      }
    ];

    this.opciones = [
      {
        label: 'Editar', icon: 'icon-pencil',
        command: (eve) => {
          this.openDestinatario();
        }
      },
      {
        label: 'Eliminar', icon: ' icon-cross2',
        command: (eve) => {
          this.eliminar();
        }
      }
    ];

  }

  ngOnInit() {
  }
  openDestinatario() {
    const modalEmp = this._matDialog.open(DestinatarioComponent,
      { width: '300px',
      height: '300px' }
    );
  }
  eliminar() {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'Destinatario Eliminado' });
  }

}
