import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule } from 'primeng/datatable';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { GrowlModule } from 'primeng/growl';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {TableModule} from 'primeng/table';

// import { MenubarModule } from 'primeng/menubar';
// import { ToolbarModule } from 'primeng/toolbar';
// import { ButtonModule } from 'primeng/button';
// import { DropdownModule } from 'primeng/dropdown';
// import { FieldsetModule } from 'primeng/fieldset';
// import { CardModule } from 'primeng/card';
// import { FileUploadModule } from 'primeng/fileupload';
// import { MultiSelectModule } from 'primeng/multiselect';
// import { SelectButtonModule } from 'primeng/selectbutton';




@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    ContextMenuModule,
    MenuModule,
    OverlayPanelModule,
    GrowlModule,
    TableModule
    // MenubarModule,
    // ToolbarModule,
    // ButtonModule,
    // DropdownModule,
    // FieldsetModule,
    // CardModule,
    // FileUploadModule,
    // MultiSelectModule,
    // SelectButtonModule,
  ],
  exports: [
    DataTableModule,
    ContextMenuModule,
    GrowlModule,
    OverlayPanelModule,
    MenuModule,
    TableModule
    // MenubarModule,
    // ToolbarModule,
    // ButtonModule,
    // DropdownModule,
    // FieldsetModule,
    // CardModule,
    // FileUploadModule,
    // MultiSelectModule,
    // SelectButtonModule,
  ],
  declarations: []
})
export class PrimengModule { }
