import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Imagen } from '../../interface/interface';

@Component({
  selector: 'app-logotipos',
  templateUrl: './logotipos.component.html',
  styleUrls: ['./logotipos.component.css']
})
export class LogotiposComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*imagen seleccionada */
  logo: string | ArrayBuffer;
  posicion = new FormControl('izquierda');
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    public _tema: TemaService,
    public dialogRef: MatDialogRef<LogotiposComponent>
    /*=====Servicios=====*/
  ) {
  }

  ngOnInit() {
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostrar la img seccionada ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  setImg(eve: Imagen) {
    this.logo = eve.direccion;    
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close(0);
  }
}
