import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(
    /*=========================Servicios=========================*/
    @Inject(MAT_DIALOG_DATA) public data: { statusOK: boolean, msg: string },
    public _matDialogRef: MatDialogRef<NotificationComponent>,
    /*=========================Servicios=========================*/
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this._matDialogRef.close();
    }, 5000);
  }

}
