import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemaService {
  tema = '';
  img: string | ArrayBuffer = '../../../assets/img/envases-logo.png';

  setLogo(logo: string | ArrayBuffer) {
    if (logo) {
      this.img = logo;
    } else {
      this.img = '../../../assets/img/envases-logo.png';
    }
  }
  saveTema() {
    localStorage.setItem('tema', JSON.stringify({ tema: this.tema, img: this.img }));
  }
  getLocalTema() {
    const temp = JSON.parse(localStorage.getItem('tema'));
    this.tema = temp.tema;
    this.img = temp.img;
  }
  hasTeme(): boolean {
    if (this.img === '' || this.img === null || this.img === undefined) {
      return false;
    }
    return true;
  }
}
