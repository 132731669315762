import { Component, OnInit } from '@angular/core';
import {TemaService } from '../../index.services';

@Component({
  selector: 'app-add-certificado',
  templateUrl: './add-certificado.component.html',
  styleUrls: ['./add-certificado.component.css']
})
export class AddCertificadoComponent implements OnInit {

  constructor(public _tema: TemaService) { }

  ngOnInit() {
  }

}
