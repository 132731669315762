import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-config',
  template: '<p>  open-config </p>'
})
export class OpenConfigComponent implements OnInit {

  constructor(
    private _Router: Router
  ) {
    localStorage.setItem('openConfig', '0');
    this._Router.navigate(['/indicador/comprobantes']);
  }

  ngOnInit() {
  }

}
