import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpService, TemaService } from '../../index.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-editar-empresa',
  templateUrl: './editar-empresa.component.html',
  styleUrls: ['./editar-empresa.component.css']
})
export class EditarEmpresaComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*formulario para editar Empresa */
  formEdit: FormGroup;
  /*bandera poara ver el formulario ya lleno*/
  verForm = false;
  /*rfc actual para poder usar el endpuint*/
  rfcActual;

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*----servicios----*/
    private _httpService: HttpService,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<EditarEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    /*----servicios----*/
  ) {
    this.formEdit = new FormGroup({
      name: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      neighborhood: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      outer_number: new FormControl('', [Validators.required]),
      inner_number: new FormControl(''),
      postal_code: new FormControl('', [Validators.required]),
      contaco: new FormControl('', [Validators.required]),
      contaco_mail: new FormControl('', [Validators.required])
    });
  }
  ngOnInit() {
    this.formEdit.controls['name'].setValue(this.data.name);
    this.formEdit.controls['rfc'].setValue(this.data.rfc);
    this.rfcActual = this.data.rfc;
    this._httpService.getCompany(this.data.rfc).subscribe(
      resp => {
        this.llenarForm(resp);
        this.formEdit.controls['contaco'].setValue(resp['contact_name']);
        this.formEdit.controls['contaco_mail'].setValue(resp['contact']);
        this.verForm = true;
      },
      err => {

      });
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Metodo para llenar el formulario ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  llenarForm(data) {
    for (const key in data.address) {
      if (data.address.hasOwnProperty(key)) {
        this.formEdit.controls[key].setValue(data.address[key]);
      }
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close();
  }

  onGuardar() {
    this._httpService.putCompany(this.rfcActual, this.armarEmp(this.formEdit.value)).subscribe(
      resp => {
        this.dialogRef.close('ok');
      },
      err => {
        this.dialogRef.close('error');
      }
    );
  }
  armarEmp(data) {
    return {
      'address': {
        'city': data.city,
        'country': data.country,
        'inner_number': data.inner_number,
        'neighborhood': data.neighborhood,
        'outer_number': data.outer_number,
        'postal_code': data.postal_code,
        'state': data.state,
        'street': data.street,
      },
      'name': data.name,
      'rfc': data.rfc,
      'contact_name': data.contaco,
      'contact': data.contaco_mail
    };
  }
}
