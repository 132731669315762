import { ReporteAuxComponent } from './../../modals/reporte-aux/reporte-aux.component';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Message } from 'primeng/api';
import { MatDialog } from '@angular/material';
// interface
import { Usuario } from '../../interface/interface';

import { AddUserComponent } from '../../modals/add-user/add-user.component';
import { EditUserComponent } from '../../modals/edit-user/edit-user.component';
import { CambiarPassComponent } from '../../modals/cambiar-pass/cambiar-pass.component';
import { FiltrosPermisosComponent } from '../../modals/filtros-permisos/filtros-permisos.component';
import { HttpService } from '../../services/http.service';
import { ConfirmacionComponent } from '../../modals/confirmacion/confirmacion.component';
import { FormControl } from '@angular/forms';
import { EditUserPaypalComponent } from 'src/app/modals/edit-user-paypal/edit-user-paypal.component';
import { LoadingComponent } from 'src/app/modals/loading/loading.component';
import { LoadingService } from 'src/app/services/loading.service';
import * as moment from 'moment';
import { editUserNotifications } from '../../modals/edit-user-paypal/edit-user-notifications';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /* etiquet anchor para descargar docs (para que fucione con firefox) */
  @ViewChild('anchorDownload') anchorDownload: ElementRef;
  listaOpc = [20, 30, 40];
  paginatorData = {
    verpag: false,
    totalDatosSel: this.listaOpc[0],
    totalDatos: 0,
    paginaActual: 1
  };
  // page = {
  //   limit: 20,
  //   offset: 0
  // };

  /*bandera de error en la conexion */
  msgError = 'carga';

  columnas = [
    // {
    //   field: 'name',
    //   header: 'Nombre'
    // },
    // {
    //   field: 'last_name',
    //   header: 'Apellido'
    // },
    {
      field: 'PaypalID',
      header: 'PayPal ID'
    },
    {
      field: 'email',
      header: 'Usuario'
    },
    {
      field: 'business_name',
      header: 'Razón social'
    }
  ];
  msgs: Message[] = [];
  opciones: MenuItem[];
  usuarios: Usuario[] = new Array();
  selccion: Usuario;
  ctrlBuscar = new FormControl('');
  timerSearch;
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*##################################### servicios #####################################*/
    public _matDialog: MatDialog,
    public _httpService: HttpService,
    private _loadingService: LoadingService
    /*##################################### servicios #####################################*/
  ) {
    _httpService.getUsers(null).subscribe(
      resp => {
        this.usuarios = this.llenarTable(resp['users']);
        this.paginatorData.totalDatos = resp['total_count'];
        this.paginatorData.verpag = true;
        this.msgError = 'sinReg';
      },
      () => {
        this.msgError = 'Error';
      }
    );

    this.opciones = [
      // {
      //   label: 'Editar', icon: 'icon-pencil', styleClass: 'purple',
      //   command: (eve) => {
      //     this.openEditUser(this.selccion);
      //   }
      // },
      // },
      {
        label: 'Editar usuario', icon: '  icon-pencil', styleClass: 'green',
        command: () => {
          this.openEditUserPaypal(this.selccion.id);
        }
      },
      {
        label: 'Activar/Desactivar facturación', icon: ' icon-switch2', styleClass: 'blue1',
        command: () => {
          this.actDesactFacturación(this.selccion);
        }
      },
      {
        label: 'Eliminar', icon: ' icon-cross2', styleClass: 'red',
        command: () => {
          this.eliminarUser(this.selccion.id);
        }
      }
    ];
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== ngOnInit ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  ngOnInit() {
    this.ctrlBuscar.valueChanges.subscribe(
      (myInputData) => {
        clearInterval(this.timerSearch);
        this.timerSearch = setTimeout(() => {
          this.reCargarTabla();
        }, 800);
      }
    );
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de crear un nuevo usuario ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openAddUser() {
    const modal = this._matDialog.open(AddUserComponent/*,{
      maxHeight: '100vh'
    }*/);
    modal.afterClosed().subscribe(msg => {
      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Usuario creado de forma correcta', 'green', 'Usuario Creado');
      } else if (msg === 'error') {
        this.showMSG('Error al crear el usuario');
      }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de editar un usuario ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openEditUser(info: Usuario) {
    const modal = this._matDialog.open(EditUserComponent,
      {
        data: { info: info }
      }
    );
    modal.afterClosed().subscribe(msg => {
      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Usuario modificado de forma correcta', 'green', 'Usuario Modificado');
      } else if (msg === 'error') {
        this.showMSG('Error al modificar el usuario');
      }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal para cambiar el pass del usuario ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openCambiarPass(info: Usuario) {
    const modal = this._matDialog.open(CambiarPassComponent,
      {
        width: '300px',
        data: { info: info }
      }
    );
    modal.afterClosed().subscribe(msg => {

      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Contraseña modificado de forma correcta', 'green', 'Contraseña Modificado');
      } else if (msg === 'error') {
        this.showMSG('Error al modificar el contraseña');
      }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal para editer los filtros ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openFiltros(info: Usuario) {
    const modal = this._matDialog.open(FiltrosPermisosComponent,
      {
        data: { info: info }
      }
    );
    modal.afterClosed().subscribe(msg => {
      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Filtros y permisos modificados de forma correcta', 'green', 'Filtros y Permisos Modificados');
      } else if (msg === 'error') {
        this.showMSG('Error al modificar los filtros y permisos');
      }
    });
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para eliminar un usuario ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  eliminarUser(id: number) {
    const modal = this._matDialog.open(ConfirmacionComponent,
      {
        data: { msg: '¿Desea eliminar el usuario?' }
      }
    );
    modal.afterClosed().subscribe(msg => {
      if (msg === 'Aceptar') {

        this._httpService.deleteUser(id).subscribe(
          () => {
            this.reCargarTabla();
            this.showMSG('Usuario eliminado de forma correcta', 'blue', 'Usuario Eliminado');
          },
          () => {
            this.showMSG('Error al eliminar el usuario');
          });
      }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para Activar/Desactivar facturación ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  actDesactFacturación(myUser: Usuario) {
    if (myUser.stop_invoices) {
      const modal = this._matDialog.open(ConfirmacionComponent,
        {
          data: { msg: 'Desea desactivar la facturación del usaurio "' + myUser.email + '"?' }
        }
      );
      modal.afterClosed().subscribe(msg => {
        if (msg === 'Aceptar') {
          this._httpService.stopInvoices(myUser.id).subscribe(
            () => {
              this.reCargarTabla();
              this.showMSG('Usuario modificado de forma correcta', 'blue', 'Usuario Modificado');
            },
            () => {
              this.showMSG('Error al modificar el usuario');
            }
          );
        }
      });
    } else {
      this._httpService.stopInvoices(myUser.id).subscribe(
        () => {
          this.reCargarTabla();
          this.showMSG('Usuario modificado de forma correcta', 'blue', 'Usuario Modificado');
        },
        () => {
          this.showMSG('Error al modificar el usuario');
        }
      );
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para filtrar la informacion requeriada para llenar la tabla ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  llenarTable(info): Usuario[] {
    const infoTable: Usuario[] = new Array();
    for (let i = 0; i < info.length; i++) {
      infoTable.push({
        id: info[i].id,
        name: info[i].name,
        last_name: info[i].last_name,
        email: info[i].email,
        business_name: info[i].business_name,
        PaypalID: info[i].paypal_id,
        stop_invoices: info[i].stop_invoices
      });
    }
    return infoTable;
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar actualizar la informacion de la tabla ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  reCargarTabla(page?: { offset, limit }) {
    this.msgError = 'carga';
    this.usuarios = [];
    this.paginatorData.verpag = false;
    if (!page) {
      this.paginatorData.paginaActual = 1;
      page = {
        limit: this.paginatorData.totalDatosSel,
        offset: 0
      };
    }

    this._httpService.getUsers(page, this.ctrlBuscar.value).subscribe(
      resp => {
        if (resp['total_count']) {
          this.usuarios = this.llenarTable(resp['users']);
          this.paginatorData.verpag = true;
        } else {
          this.msgError = 'sinReg';
          this.usuarios = [];
        }
        this.paginatorData.totalDatos = resp['total_count'];
      },
      () => {
        this.msgError = 'Error';
      }
    );
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostar el mensaje del resultado de la accion realizada (agregar, actualizar, eliminar) ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  showMSG(mensaje: string, color?: string, titulo?: string, ) {
    let icono = 'error';
    if (!color && !titulo) {
      titulo = 'ERROR!';
    } else {
      switch (color) {
        case 'blue':
          icono = 'info';
          break;
        case 'green':
          icono = 'success';
          break;

        default:
          icono = 'error';
          break;
      }
    }
    this.msgs = [];
    this.msgs.push({ severity: icono, summary: titulo, detail: mensaje });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de editar usuario de paypal ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openEditUserPaypal(myID) {
    const modal = this._matDialog.open(EditUserPaypalComponent, {
      disableClose: true,
      maxHeight: '99%',
      maxWidth: '99%',
      data: { id: myID }
    });
    modal.afterClosed().subscribe(msg => {
      this.msgs = [];
      const notification = editUserNotifications[msg]
      if (notification) {
        this.msgs.push(notification)
      }
      if (msg === "ok") {
        this.reCargarTabla();
      }
    });
  }

  onChangePage(eve) {
    this.paginatorData.paginaActual = eve['numPagActual'];
    this.paginatorData.totalDatosSel = eve['selTotalPag'];
    this.reCargarTabla({ limit: eve.limit, offset: eve.offset });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de descargar reporte ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openReporte() {
    const modal = this._matDialog.open(ReporteAuxComponent, {
      disableClose: true,
      maxHeight: '99%',
      maxWidth: '99%'
    });
    modal.afterClosed().subscribe(
      (myResp) => {
        if (myResp) {
          this.showMSG('Error en la descarga');
        }
      }
    );
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para abrir el modal de descargar reporte de usuarios ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openReporteUsuarios() {
    this.openModalLoading();
    this._httpService.getReportMerchants().subscribe(
      resp => {
        this._loadingService.eveCerrar.next(true);
        const name = 'Reporte_de_usuarios_' + this.formatoFecha(new Date(), 'lll');
        this.getFile(resp, 'csv', name);
      },
      () => {
        this._loadingService.eveCerrar.next(false);
        this.showMSG('Error al obtener el archivo');
      }
    );

  }

  openModalLoading() {
    this._matDialog.open(LoadingComponent, {
      panelClass: 'loading-modal',
      disableClose: true,
      data: { total: -1 }
    });
  }

  getFile(info, tipo: string, nombre: string, addExtencion: boolean = false) {
    const newBlob = new Blob([info], { type: 'application/' + tipo });
    const data = window.URL.createObjectURL(newBlob);
    this.anchorDownload.nativeElement.href = data;
    if (addExtencion) {
      this.anchorDownload.nativeElement.download = nombre;
    } else {
      this.anchorDownload.nativeElement.download = nombre + '.' + tipo;
    }
    this.anchorDownload.nativeElement.click();
  }

  formatoFecha(fecha: Date, formato: string) {
    return moment(fecha).locale('es').format(formato);
  }
}
