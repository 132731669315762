import { Component, OnInit, Inject } from '@angular/core';
import { TemaService } from '../../index.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpService } from '../../index.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-cambiar-pass',
  templateUrl: './cambiar-pass.component.html',
  styleUrls: ['./cambiar-pass.component.css']
})
export class CambiarPassComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  formCambiarPass: FormGroup;
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/

  constructor(
    /*=====Servicios=====*/
    public _httpService: HttpService,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<CambiarPassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    /*=====Servicios=====*/
  ) {
    this.formCambiarPass = new FormGroup({
      pass: new FormControl('', [Validators.required]),
      repPass: new FormControl()
    });
  }

  ngOnInit() {
    this.formCambiarPass.controls['repPass'].setValidators([Validators.required, this.pasIgual.bind(this.formCambiarPass)]);
    this.formCambiarPass.controls['pass'].valueChanges.subscribe(() => {
      this.formCambiarPass.controls['repPass'].updateValueAndValidity();
    });
  }


  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*===validacion personalizada para verificar
  contraseñas iguales ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  pasIgual(pass: FormControl): { [s: string]: boolean } {
    const repPass: any = this;
    if (pass.value !== repPass.controls['pass'].value) {
      return { noiguales: true };
    }
    return null;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close();
  }

  onGuardar() {
    this.formCambiarPass.controls['pass'].markAsTouched();
    this.formCambiarPass.controls['repPass'].markAsTouched();
    const newData = {
      pass: this.formCambiarPass.controls['pass'].value
    };
    if (this.formCambiarPass.valid) {
      this._httpService.patchUser(this.data.info.id, newData).subscribe(
        () => {
          this.dialogRef.close('ok');

        },
        () => {
          this.dialogRef.close('error');

        }
      );
    }

  }
}
