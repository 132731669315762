import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

interface Auditoria {
  facturaId: string;
  auditoriaId: string;
  fecha: string;
  hora: string;
  tiempoProcesamiento: string;
  versionSellado: string;
}

@Component({
  selector: 'app-auditorias',
  templateUrl: './auditorias.component.html',
  styleUrls: ['./auditorias.component.css']
})
export class AuditoriasComponent implements OnInit {
  Auditorias: Auditoria[];


  constructor() {
    this.Auditorias = [
      { facturaId: '1', auditoriaId: '1', fecha: '24/03/1986', hora: '16:00', tiempoProcesamiento: 'abc', versionSellado: 'bvn' },
      { facturaId: '2', auditoriaId: '2', fecha: '28/09/1989', hora: '15:00', tiempoProcesamiento: 'sad', versionSellado: 'yui' }
    ];
  }

  ngOnInit() {
  }

}
