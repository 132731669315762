import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material';

import { TemaService, LoginService, PermisosService } from '../../index.services';
import { EditUserPaypalComponent } from '../../modals/edit-user-paypal/edit-user-paypal.component';
import { Message } from 'primeng/api';
import { editUserNotifications } from '../../modals/edit-user-paypal/edit-user-notifications';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  msgs: Message[] = [];
  nombreUser = 'user';


  constructor(
    /*=====Servicios=====*/
    public dialog: MatDialog,
    public _tema: TemaService,
    public _login: LoginService,
    public _permisosService: PermisosService
    /*=====Servicios=====*/
  ) {
    if (localStorage.getItem('usuario')) {
      this.nombreUser = JSON.parse(localStorage.getItem('usuario')).mail;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      const open = localStorage.getItem('openConfig');
      if (open) {
        this.onConfigUser();
        localStorage.removeItem('openConfig');
      }
    }, 1000);
  }

  onLogout() {
    this._login.logout();
  }

  onConfigUser() {
    const modal = this.dialog.open(EditUserPaypalComponent, {
      disableClose: true,
      maxHeight: '99%',
      maxWidth: '99%',
      data: { id: null }
    });
    modal.afterClosed().subscribe(msg => {
      this.msgs = [];
      const notification = editUserNotifications[msg]
      if (notification) {
        this.msgs.push(notification)
      }
    });
  }
}
