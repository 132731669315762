import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../index.services';
import { LoginUser } from '../interface/interface';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  //  Bandera para circulo de carga
  verCargar = false;
  // Bandera si preciono el boton de login
  intentoLogin = false;
  // formulario de login
  formLogin: FormGroup;



  // verPass = true;
  // formatoPass = 'password';




  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*+++++servicios+++++*/
    public _loginService: LoginService,
    private _router: Router
    /*-----servicios -----*/
  ) {
    /*componentes del formulario*/
    this.formLogin = new FormGroup({
      user: new FormControl('', [Validators.required]),
      pass: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para dar login ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  entrar() {
    const info: LoginUser = this.formLogin.value;
    this.formLogin.controls['user'].markAsTouched();
    this.formLogin.controls['pass'].markAsTouched();
    if (this.formLogin.valid) {
      this.intentoLogin = true;
      this.verCargar = true;
      this.formLogin.disable();
      this._loginService.login(info).subscribe(
        (msg: HttpResponse<any>) => {
          this._loginService.setLoged();
          // this._router.navigate(['/home']);
        },
        err => {
          this.verCargar = false;
          this.formLogin.enable();
        });
    }
  }
}
