import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TemaService } from '../../index.services';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  time;
  constructor(
    /*=====Servicios=====*/
    public _loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    public _tema: TemaService
    /*=====Servicios=====*/
  ) {
  }

  ngOnInit() {
    this.time = setTimeout(() => {
      this._loginService.logout();
      this.dialogRef.closeAll();
    }, 6000);
  }

  onAceptar() {
    clearTimeout(this.time);
    this._loginService.logout();
    this.dialogRef.closeAll();
  }

}
