import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TemaService } from '../../services/tema.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  @Input() total = 100;
  @Input() largoActual = 20;
  @Input() opcTotalPag: number[] = [20, 50, 80];

  @Input() numPagActual = 1;
  @Input() selTotalPag = this.opcTotalPag[0];

  @Output() paginator: EventEmitter<{ limit: number, offset: number, numPagActual: number, selTotalPag: number }> = new EventEmitter();
  numPag: FormControl;
  totalPag;
  offset: FormControl;

  incioActual;
  finActual;

  btnFastBackward;
  btnBackward;
  btnForward;
  btnFastForward;

  controlRep = true;

  constructor(
    /*=====Servicios=====*/
    public _tema: TemaService
    /*=====Servicios=====*/
  ) { }

  ngOnInit() {
    this.offset = new FormControl(this.selTotalPag);
    this.numPag = new FormControl(this.numPagActual);

    this.totalPag = Math.trunc(this.total / this.selTotalPag);
    if (this.total % this.selTotalPag > 0) {
      this.totalPag += 1;
    }
    this.updateInfo();
    if (this.numPagActual > 1) {
      this.btnFastBackward = false;
      this.btnBackward = false;
    } else {
      this.btnFastBackward = true;
      this.btnBackward = true;
    }
    if (this.numPagActual < this.totalPag) {
      this.btnForward = false;
      this.btnFastForward = false;
    } else {
      this.btnForward = true;
      this.btnFastForward = true;
    }


    this.numPag.valueChanges.subscribe(val => {
      if (val > 0 && val <= this.totalPag) {
        if (val > 1) {
          this.btnFastBackward = false;
          this.btnBackward = false;
        } else {
          this.btnFastBackward = true;
          this.btnBackward = true;
        }
        if (val < this.totalPag) {
          this.btnForward = false;
          this.btnFastForward = false;
        } else {
          this.btnForward = true;
          this.btnFastForward = true;
        }
      } else {
        this.btnFastBackward = true;
        this.btnBackward = true;
        this.btnForward = true;
        this.btnFastForward = true;
      }
    });
    this.offset.valueChanges.subscribe(val => {
      this.numPag.setValue(1);
      this.updateInfo();
      this.emitir();
    });
  }

  onSelPagEnter() {
    this.controlRep = false;
    if (this.numPag.value < 1) {
      this.numPag.setValue(1);
    } else if (this.numPag.value > this.totalPag) {
      this.numPag.setValue(this.totalPag);
    } else if (!(this.numPag.value >= 1 && this.numPag.value <= this.totalPag)) {
      this.numPag.setValue(1);
    }
    this.updateInfo();
    this.emitir();
  }
  onSelPagBlur() {
    if (this.controlRep) {
      if (this.numPag.value < 1) {
        this.numPag.setValue(1);
      } else if (this.numPag.value > this.totalPag) {
        this.numPag.setValue(this.totalPag);
      } else if (!(this.numPag.value >= 1 && this.numPag.value <= this.totalPag)) {
        this.numPag.setValue(1);
      }
      this.updateInfo();
      this.emitir();
    } else {
      this.controlRep = true;
    }
  }

  emitir() {
    const infoPag = {
      offset: this.incioActual - 1,
      limit: this.offset.value,
      numPagActual: this.numPag.value,
      selTotalPag: this.offset.value
    };
    this.paginator.emit(infoPag);
  }

  onFastBackward() {
    this.numPag.setValue(1);
    this.updateInfo();
    this.emitir();
  }
  onBackward() {
    const val = Number(this.numPag.value) - 1;
    this.numPag.setValue(val);
    this.updateInfo();
    this.emitir();
  }
  onForward() {
    const val = Number(this.numPag.value) + 1;
    this.numPag.setValue(val);
    this.updateInfo();
    this.emitir();
  }
  onFastForward() {
    this.numPag.setValue(this.totalPag);
    this.updateInfo();
    this.emitir();
  }

  updateInfo() {
    this.incioActual = ((this.numPag.value - 1) * this.selTotalPag) + 1;
    this.finActual = (this.incioActual - 1) + this.largoActual;
  }
}
