import { MsgXmlPdfComponent } from "./../msg-xml-pdf/msg-xml-pdf.component";
import { ConfirmacionComponent } from "./../confirmacion/confirmacion.component";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { HttpService } from "../../services/http.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TaxRegimeCatalog } from "../../sat-catalogs/tax-regime";
import { User } from "../../services/http-interfaces";
import { getAuthUser } from "../../services/auth-token";
import { NotificationService } from "src/app/services/notifications/notification.service";

@Component({
  selector: "app-edit-user-paypal",
  templateUrl: "./edit-user-paypal.component.html",
  styleUrls: ["./edit-user-paypal.component.scss"],
})
export class EditUserPaypalComponent implements OnInit {
  configurationForm: FormGroup;
  initialRfc = "";
  isLoading = false;
  taxRegimeCatalog = TaxRegimeCatalog;

  confirmedInformation = new FormControl(false);
  constructor(
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<EditUserPaypalComponent>,
    private httpService: HttpService,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      disableClose?: boolean;
      title?: string;
      complete?: boolean;
    }
  ) {
    this.configurationForm = new FormGroup({
      business_name: new FormControl(null, [Validators.required]),
      receptor_rfcs: new FormControl(null, [Validators.required]),
      zip_code: new FormControl(null, [Validators.required]),
      tax_regime: new FormControl(null, [Validators.required]),
    });

    if (!this.data.id) {
      const loggedUser = getAuthUser();
      if (loggedUser) {
        this.data.id = loggedUser.id;
      } else {
        this.dialogRef.close("error_user");
      }
    }

    if (this.data.id) {
      this.getUser();
    }
  }

  ngOnInit() {}

  getUser(): void {
    this.httpService.getUser(this.data.id).subscribe(
      (user: User) => {
        this.configurationForm
          .get("business_name")
          .setValue(user.business_name);
        this.initialRfc = user.receptor_rfcs.slice(-1)[0];
        this.configurationForm.get("receptor_rfcs").setValue(this.initialRfc);
        this.configurationForm.get("zip_code").setValue(user.zip_code);
        this.configurationForm.get("tax_regime").setValue(user.tax_regime);
      },
      () => {
        this.dialogRef.close();
      }
    );
  }

  saveConfiguration(): void {
    for (const key in this.configurationForm.controls) {
      this.configurationForm.controls[key].markAsTouched();
    }
    if (this.configurationForm.valid) {
      if (this.confirmedInformation.value) {
        const rfc: string = this.configurationForm.get("receptor_rfcs").value;
        this.isLoading = true;
        if (rfc === this.initialRfc) {
          this.updateUser();
          return;
        }
        this.validRfc(rfc);
      } else {
        this._notificationService.openNotification(
          "Por favor introduce los datos tal cual aparecen en tu Constancia de Situación Fiscal, de otra manera PayPal México no podrá emitir tus CFDIs por las nuevas disposiciones del SAT."
        );
      }
    }
  }

  validRfc(rfc: string): void {
    this.httpService.validaUserRFC(rfc).subscribe(
      (resp: { valid: boolean }) => {
        if (resp.valid) {
          this.updateUser();
        } else {
          this.showRfcChangedConfirmation();
        }
      },
      () => {
        this.dialogRef.close("error");
      }
    );
  }

  updateUser(): void {
    const updatedUser = {
      ...this.configurationForm.value,
      receptor_rfcs: [this.configurationForm.get("receptor_rfcs").value],
    };
    updatedUser["business_name"] = (
      updatedUser["business_name"] as string
    ).toUpperCase();
    this.httpService.patchUser(this.data.id, updatedUser).subscribe(
      () => {
        this.dialogRef.close("ok");
      },
      (err) => {
        if (err.error.message && err.error.message === "rfc not valid") {
          this.showMessage("RFC no válido por el SAT");
          this.isLoading = false;
        } else {
          this.dialogRef.close("error");
        }
      }
    );
  }

  showRfcChangedConfirmation(): void {
    const rfc = this.configurationForm.get("receptor_rfcs").value;
    const modal = this.matDialog.open(ConfirmacionComponent, {
      data: {
        msg: `El RFC ${rfc} ya está en uso.
        ¿Desea continuar?`,
      },
    });
    modal.afterClosed().subscribe((msg) => {
      if (msg === "Aceptar") {
        this.updateUser();
      } else {
        this.isLoading = false;
      }
    });
  }

  showMessage(msg: string): void {
    this.matDialog.open(MsgXmlPdfComponent, {
      minWidth: "250px",
      data: { msg: msg },
    });
  }
}
