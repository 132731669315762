import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  previousUrl: string;
  constructor(
    /*##################################### servicios #####################################*/
    private sRouter: Router
    /*##################################### servicios #####################################*/
  ) {
    sRouter.events.pipe(filter((myE) => {
      return myE instanceof NavigationEnd;
    })).subscribe(
      (myEvent: NavigationEnd) => {
        this.previousUrl = myEvent.url;
      }
    );
  }

  ngOnInit() {
  }

}
