import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loadin-tables',
  templateUrl: './loadin-tables.component.html',
  styleUrls: ['./loadin-tables.component.css']
})
export class LoadinTablesComponent implements OnInit {
  @Input() infoTable: number;
  @Input() msgError: 'carga' | 'sinReg' | 'Error' = 'Error';

  constructor() { }

  ngOnInit() {
  }

}
