import { Component, OnInit, Inject } from '@angular/core';
import { TemaService } from '../../index.services';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';

interface FiltroFiscal {
  filtro: string;
}

@Component({
  selector: 'app-filtros-permisos',
  templateUrl: './filtros-permisos.component.html',
  styleUrls: ['./filtros-permisos.component.css']
})
export class FiltrosPermisosComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/

  permisos: { label: string, tip: string }[] = [
    { label: 'Administrar usuarios', tip: 'Permiso para poder agregar, editar o eliminar usuarios' },
    { label: 'Administrar empresas', tip: 'Permiso para poder agregar, editar o eliminar empresas' },
    { label: 'Configuración SAT', tip: 'Permiso para poder realizar las configuraciones de SAT' },
    { label: 'Cancelar documentos', tip: 'Permiso para poder cancelar un CFDI' },
    { label: 'Envío de comprobante por correo', tip: 'Permiso para poder enviar un correo con el CFDI desde comprobantes' }
  ];
  /*formulario de permisos */
  formFiltros: FormGroup;
  /*lista de separadores el RFC emisor */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /*banderas de de candado para la seleccion de los permisos y comprobantes */
  checkPermisos = [true, true];
  /*bandera para validar las listas de las empresas */
  verErrorlistaEmpresas = false;
  /*lista de enpresas disponibles para selccionar */
  listaEmpresas: string[];
  /*lista de RFC receptores seleccionados */
  rfcReceptor: any[] = [];


  infoUser;

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    private _httpService: HttpService,
    public _tema: TemaService,
    public dialogRef: MatDialogRef<FiltrosPermisosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    /*=====Servicios=====*/
  ) {
    /*controles de el formulario del filtro */
    this.formFiltros = new FormGroup({
      rol: new FormControl('', [Validators.required]),
      // empresaEmi: new FormControl({ value: '', disabled: true }, [Validators.required]),
      empresaEmi: new FormArray([]),
      limitarRFC: new FormControl(false),
      receptorRFC: new FormControl(''),
      todosComprobantes: new FormControl(false),
      comprobantes: new FormArray([]),
      todosPermisos: new FormControl(false),
      permisos: new FormArray([])
    });
    /*llenado del arreglo de permisos */
    for (let element = 0; element < this.permisos.length; element++) {
      (<FormArray>this.formFiltros.controls['permisos']).push(
        new FormControl(false)
      );
    }

    /*llenado de la lista de empresas disponibles para selccionar */
    const masterID = JSON.parse(localStorage.getItem('usuario')).id;
    _httpService.getUser(masterID).subscribe(
      resp => {
        this.listaEmpresas = resp['company_rfcs'];
        // // this.formFiltros.controls['empresaEmi'].setErrors({ incorrect: true });
        for (let emp = 0; emp < this.listaEmpresas.length; emp++) {
          (<FormArray>this.formFiltros.controls['empresaEmi']).push(
            new FormControl({ value: false, disabled: true })
          );
        }

        /*lenado de la informacion de usuario */
        const userID = data.info['id'];
        _httpService.getUser(userID).subscribe(
          resp2 => {
            this.infoUser = resp2;
            this.formFiltros.controls['rol'].setValue(resp2['type']);
            if (this.formFiltros.controls['rol'].value !== 'admin') {
              this.empEmiSelcted(this.infoUser.company_rfcs, this.listaEmpresas);
            }
            if (resp2['receptor_rfcs'].length > 0) {
              this.formFiltros.controls['limitarRFC'].setValue(true);
              this.rfcReceptor = resp2['receptor_rfcs'];
              this.formFiltros.controls['receptorRFC'].setValue(' ');
            } else {
              this.formFiltros.controls['limitarRFC'].setValue(false);
              this.rfcReceptor = [];
            }
            this.llenarPermisos(resp2['permissions']);
          },
          err => { }
        );



      },
      err => { }
    );
  }

  ngOnInit() {


    this.formFiltros.controls['empresaEmi'].valueChanges.subscribe(val => {
      for (let i = 0; i < val.length; i++) {
        this.formFiltros.controls['empresaEmi'].setErrors({ incorrect: true });
        if (val[i]) {
          this.formFiltros.controls['empresaEmi'].setErrors(null);
          break;
        }
      }
    });

    /*llenado de todos los checks de comprobantes con el check de selccionar todos */
    this.formFiltros.controls['todosComprobantes'].valueChanges.subscribe(val => {
      /*if candado para evitar el ciclo infinito formado por el Observable de los value changes */
      if (this.checkPermisos[0]) {
        this.checkPermisos[1] = false;
        for (let i = 0; i < (<FormArray>this.formFiltros.controls['comprobantes']).length; i++) {
          (<FormArray>this.formFiltros.controls['comprobantes']).controls[i].setValue(val);
        }
        this.checkPermisos[1] = true;
      }
    });
    /*llenado del check de seleccionar todos los comprobantes si se selccionan todos de forma manual*/
    this.formFiltros.controls['comprobantes'].valueChanges.subscribe(val => {
      /*if candado para evitar el ciclo infinito formado por el Observable de los value changes */
      if (this.checkPermisos[1]) {
        this.checkPermisos[0] = false;
        for (let i = 0; i < val.length; i++) {
          if (!val[i]) {
            this.formFiltros.controls['todosComprobantes'].setValue(false);
            break;
          } else {
            this.formFiltros.controls['todosComprobantes'].setValue(true);
          }
        }
        this.checkPermisos[0] = true;
      }
    });
    /*si selcciona rol de administrador ('0') habilitar y limpiar, o deshabilitar y llenar
     el componente de selccion de empresa */
    this.formFiltros.controls['rol'].valueChanges.subscribe(resp => {
      if (resp === 'admin') {

        this.llenarEmpEmi(true);
        this.formFiltros.controls['todosComprobantes'].setValue(true);
        this.formFiltros.controls['todosComprobantes'].disable();
        this.formFiltros.controls['comprobantes'].disable();
        this.formFiltros.controls['todosPermisos'].setValue(true);
        this.formFiltros.controls['todosPermisos'].disable();
        this.formFiltros.controls['permisos'].disable();
      } else {

        this.llenarEmpEmi(false);
        this.formFiltros.controls['todosComprobantes'].setValue(false);
        this.formFiltros.controls['todosComprobantes'].enable();
        this.formFiltros.controls['comprobantes'].enable();
        this.formFiltros.controls['todosPermisos'].setValue(false);
        this.formFiltros.controls['todosPermisos'].enable();
        this.formFiltros.controls['permisos'].enable();
      }
    });

    /*asignar o quitar el validador del componente de lista de receptores de RFC segun
    si activa el limitarRFC */
    this.formFiltros.controls['limitarRFC'].valueChanges.subscribe(resp => {
      if (resp) {
        this.formFiltros.controls['receptorRFC'].setValidators([Validators.required]);
        if (this.rfcReceptor.length < 1) {
          this.formFiltros.controls['receptorRFC'].setErrors({ 'incorrect': true });
        }
      } else {
        this.formFiltros.controls['receptorRFC'].clearValidators();
        this.formFiltros.controls['receptorRFC'].setErrors(null);
        this.rfcReceptor = [];
      }
    });

    /*asignar error si se selcciona limitar rfc y no se llena el campo de receporRFC */
    this.formFiltros.controls['receptorRFC'].valueChanges.subscribe(resp => {
      if (resp.length < 1 && this.rfcReceptor.length > 0) {
        this.formFiltros.controls['receptorRFC'].setErrors(null);
      }
    });
    /*llenado del check de seleccionar todos los permisos si se selccionan todos de forma manual*/
    this.formFiltros.controls['permisos'].valueChanges.subscribe(val => {
      /*if candado para evitar el ciclo infinito formado por el Observable de los value changes */
      if (this.checkPermisos[1]) {
        this.checkPermisos[0] = false;
        for (let i = 0; i < val.length; i++) {
          if (!val[i]) {
            this.formFiltros.controls['todosPermisos'].setValue(false);
            break;
          } else {
            this.formFiltros.controls['todosPermisos'].setValue(true);
          }
        }
        this.checkPermisos[0] = true;
      }
    });
    /*llenado de todos los checks de permisos con el check de selccionar todos */
    this.formFiltros.controls['todosPermisos'].valueChanges.subscribe(val => {
      /*if candado para evitar el ciclo infinito formado por el Observable de los value changes */
      if (this.checkPermisos[0]) {
        this.checkPermisos[1] = false;
        for (let i = 0; i < (<FormArray>this.formFiltros.controls['permisos']).length; i++) {
          (<FormArray>this.formFiltros.controls['permisos']).controls[i].setValue(val);
        }
        this.checkPermisos[1] = true;
      }
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar los permisos obtenidos del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  private llenarPermisos(info) {
    if (!info) {
      info = [];
    }
    for (let index = 0; index < info.length; index++) {
      const element = info[index];
      switch (element) {
        case 'users.crud':
          (<FormArray>this.formFiltros.controls['permisos']).controls[0].setValue(true);

          break;
        case 'company.crud':
          (<FormArray>this.formFiltros.controls['permisos']).controls[1].setValue(true);

          break;
        case 'cert.upload':
          (<FormArray>this.formFiltros.controls['permisos']).controls[2].setValue(true);

          break;
        // case 'cfdi.files':
        //   (<FormArray>this.formFiltros.controls['permisos']).controls[3].setValue(true);

        //   break;
        case 'cfdi.cancel':
          (<FormArray>this.formFiltros.controls['permisos']).controls[3].setValue(true);

          break;
        case 'cfdi.mail':
          (<FormArray>this.formFiltros.controls['permisos']).controls[3].setValue(true);

          break;
      }
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para quitar empresa de la lista de
  refReceptores ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  remove(rfc): void {
    const index = this.rfcReceptor.indexOf(rfc);
    if (index >= 0) {
      this.rfcReceptor.splice(index, 1);
    }
    if (this.rfcReceptor.length < 1) {
      this.formFiltros.controls['receptorRFC'].setErrors({ 'incorrect': true });
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para agregar empresa de la lista
  de refReceptores ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.rfcReceptor.push(value);
      this.formFiltros.controls['receptorRFC'].setErrors(null);
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para salir del modal ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  cancelar() {
    this.dialogRef.close();
  }

  guardar() {
    this.verErrorlistaEmpresas = true;
    this.formFiltros.controls['receptorRFC'].markAsTouched();

    let rfcEmi = new Array();
    if (this.formFiltros.controls['rol'].value === 'admin') {
      rfcEmi = this.listaEmpresas;
    } else {
      // rfcEmi = this.formFiltros.controls['empresaEmi'].value;
      rfcEmi = this.getEmpEmi();
    }
    const informacion = {
      // name: this.infoUser.name,
      // last_name: this.infoUser.last_name,
      // email: this.infoUser.email,
      company_rfcs: rfcEmi,
      receptor_rfcs: this.rfcReceptor,
      permissions: this.getPermisos(this.formFiltros.controls['permisos'].value),
      type: this.formFiltros.controls['rol'].value
    };
    if (this.formFiltros.valid) {
      this._httpService.patchUser(this.infoUser.id, informacion).subscribe(
        () => {
          this.dialogRef.close('ok');
        },
        () => {
          this.dialogRef.close('error');
        }
      );
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar todas la empresas
  emisoras  ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  private llenarEmpEmi(val: boolean) {
    for (let i = 0; i < (<FormArray>this.formFiltros.controls['empresaEmi']).length; i++) {
      (<FormArray>this.formFiltros.controls['empresaEmi']).controls[i].setValue(val);
      if (val) {
        (<FormArray>this.formFiltros.controls['empresaEmi']).controls[i].disable();
      } else {
        (<FormArray>this.formFiltros.controls['empresaEmi']).controls[i].enable();
      }
    }
  }

  private getEmpEmi(): string[] {
    const r: string[] = new Array();
    for (let i = 0; i < (<FormArray>this.formFiltros.controls['empresaEmi']).length; i++) {

      const isCheked = (<FormArray>this.formFiltros.controls['empresaEmi']).controls[i].value;
      if (isCheked) {
        r.push(this.listaEmpresas[i]);
      }
    }
    return r;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para marcar las empresas que ya
  fueron seleccionadas ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  private empEmiSelcted(totalEmp: string[], empSelcted: string[]) {
    for (let x = 0; x < empSelcted.length; x++) {
      for (let y = 0; y < totalEmp.length; y++) {
        if (empSelcted[x] === totalEmp[y]) {
          (<FormArray>this.formFiltros.controls['empresaEmi']).controls[x].setValue(true);
          break;
        }
      }
    }
  }

  private getPermisos(perSel): string[] {
    const temp = [];
    for (let index = 0; index < perSel.length; index++) {
      const element = perSel[index];
      if (element) {
        switch (index) {
          case 0:
            temp.push('users.crud');
            break;
          case 1:
            temp.push('company.crud');
            break;
          case 2:
            temp.push('cert.upload');
            break;
          // case 3:
          //   temp.push('cfdi.files');
          //   break;
          case 3:
            temp.push('cfdi.cancel');
            break;
          case 4:
            temp.push('cfdi.mail');
            break;
        }
      }
    }
    temp.push('cfdi.files');
    return temp;
  }


  get FC_empresaEmi() {
    return (<FormArray>this.formFiltros.controls['empresaEmi']).controls;
  }
  get FC_permisos() {
    return (<FormArray>this.formFiltros.controls['permisos']).controls;
  }
}
