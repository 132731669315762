export interface CfdiInterface {
    id: number;
    estatus: string;
    serie: string;
    folio: number;
    folioReferencia: string;
    version: string;
    rfcEmisor: string;
    nombreEmisor: string;
    rfcReceptor: string;
    nombreReceptor: string;
    paypalID: string;
    // fechaEmision: string;
    fechaCreacion: string;
    total: number;
    moneda: string;
    uuid: string;
    detalle: string;
    extra1: string;
    extra2: string;
    extra3: string;
    extra4: string;
    extra5: string;
    extra6: string;
    extra7: string;
    extra8: string;
    extra9: string;
    extra10: string;
}
export class CfdiModelo {
    modelo = [
        {
            field: 'id',
            header: 'ID manager',
            width: '450px',
            setOption: true,
            ver: false,
            filter: true,
            sort: true
        },
        {
            field: 'estatus',
            header: 'Estatus',
            width: '115px',
            setOption: true,
            ver: true,
            filter: false,
            sort: true
        },
        {
            field: 'serie',
            header: 'Serie',
            width: '90px',
            setOption: true,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'folioReferencia',
            header: 'Folio de referencia',
            width: '180px',
            setOption: true,
            ver: true,
            filter: true,
            sort: true
        },
        {
            field: 'paypalID',
            header: 'Payer ID',
            width: '150px',
            setOption: true,
            ver: true,
            filter: false,
            sort: false
        },
        {
            field: 'folio',
            header: 'Folio',
            width: '150px',
            setOption: true,
            ver: false,
            filter: false,
            sort: false
        },
        // {
        //     field: 'folio',
        //     header: 'Folio',
        //     width: '150px',
        //     setOption: true,
        //     ver: false,
        //     filter: false,
        //     sort: false
        // },
        {
            field: 'version',
            header: 'Versión',
            width: '90px',
            setOption: true,
            ver: true,
            filter: false,
            sort: false
        },
        {
            field: 'rfcEmisor',
            header: 'RFC emisor',
            width: '120px',
            setOption: true,
            ver: true,
            filter: false,
            sort: true
        },
        {
            field: 'nombreEmisor',
            header: 'Nombre emisor',
            width: '190px',
            setOption: true,
            ver: true,
            filter: true,
            sort: true
        },
        {
            field: 'rfcReceptor',
            header: 'RFC receptor',
            width: '120px',
            setOption: true,
            ver: true,
            filter: true,
            sort: true
        },
        {
            field: 'nombreReceptor',
            header: 'Nombre receptor',
            width: '150px',
            setOption: true,
            ver: true,
            filter: true,
            sort: true
        },
        // {
        //     field: 'fechaEmision',
        //     header: 'Fecha Emision',
        //     width: '120px',
        //     ver: true,
        //     filter: true,
        // sort: true
        // },
        {
            field: 'fechaCreacion',
            header: 'Fecha creación',
            width: '120px',
            setOption: true,
            ver: true,
            filter: false,
            sort: true
        },
        {
            field: 'total',
            header: 'Total',
            width: '90px',
            setOption: true,
            ver: true,
            filter: true,
            sort: true
        },
        {
            field: 'moneda',
            header: 'Moneda',
            width: '90px',
            setOption: true,
            ver: true,
            filter: false,
            sort: false
        },
        {
            field: 'uuid',
            header: 'UUID',
            width: '270px',
            setOption: true,
            ver: true,
            filter: true,
            sort: false
        },
        {
            field: 'extra1',
            // header: 'Fecha Inicial Pago',
            header: 'Extra 1',
            width: '115px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra2',
            // header: 'Fecha Final Pago',
            header: 'Extra 2',
            width: '115px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra3',
            header: 'Extra 3',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra4',
            header: 'Extra 4',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra5',
            header: 'Extra 5',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra6',
            header: 'Extra 6',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra7',
            header: 'Extra 7',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra8',
            header: 'Extra 8',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra9',
            header: 'Extra 9',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'extra10',
            header: 'Extra 10',
            width: '80px',
            setOption: false,
            ver: false,
            filter: false,
            sort: false
        },
        {
            field: 'detalle',
            header: 'Detalle',
            width: '100px',
            setOption: true,
            ver: true,
            filter: false,
            sort: false
        }
    ];
}

export class OpMes {
    lista = [
        { label: 'Enero', value: '1' },
        { label: 'Febrero', value: '2' },
        { label: 'Marzo', value: '3' },
        { label: 'Abril', value: '4' },
        { label: 'Mayo', value: '5' },
        { label: 'Junio', value: '6' },
        { label: 'Julio', value: '7' },
        { label: 'Agosto', value: '8' },
        { label: 'Septiembre', value: '9' },
        { label: 'Octubre', value: '10' },
        { label: 'Noviembre', value: '11' },
        { label: 'Diciembre', value: '12' }
    ];
}

export class OpYear {
    now = new Date().getFullYear();
    getYears(): number[] {
        let year = this.now;
        const lista: number[] = [];
        while (year >= 2019) {
            lista.push(year);
            year -= 1;
        }
        return lista;
    }
}

