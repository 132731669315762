import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TemaService } from 'src/app/services/tema.service';
import { HttpService } from '../../services/http.service';
import { Underscore } from '../../classes/underscore';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.css']
})
export class ExtrasComponent implements OnInit {
  under = new Underscore();

  listaFiltro = [
    'extra1_name',
    'extra2_name',
    'extra3_name',
    'extra4_name',
    'extra5_name',
    'extra6_name',
    'extra7_name',
    'extra8_name',
    'extra9_name',
    'extra10_name'
  ];
  nombre = '';
  id = '';
  extras: {
    'extra': string;
    'habilitar': FormControl;
    'nombre': FormControl;
    'backNombre': string;
  }[] = new Array();
  cargarInfo = 0;
  /*bandera de error en la conexion */
  msgError = 'carga';
  msgs: Message[] = [];
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    public _temaService: TemaService,
    public _HttpService: HttpService
    /*=====Servicios=====*/
  ) {
    const totalExtras = 10;
    for (let i = 0; i < totalExtras; i++) {
      this.extras.push({
        extra: 'Extra ' + (i + 1),
        habilitar: new FormControl(false),
        nombre: new FormControl({ value: '', disabled: true }),
        backNombre: this.listaFiltro[i]
      });
    }
    _HttpService.getGroups().subscribe(
      resp => {
        this.id = resp['id'];
        this.nombre = resp['name'];
        this.lleanrTabla(resp);
        this.cargarInfo = 1;
        this.msgError = 'sinReg';
      },
      () => {
        this.msgError = 'Error';
      }
    );
  }

  ngOnInit() {
    for (let i = 0; i < this.extras.length; i++) {
      this.extras[i].habilitar.valueChanges.subscribe(val => {
        this.setActivar(i, val);
      });
    }
  }
  onGuardar() {
    const newData = { name: this.nombre };
    for (let i = 0; i < this.extras.length; i++) {
      const element = this.extras[i];
      if (element.habilitar.value) {
        newData[this.listaFiltro[i]] = element.nombre.value;
      }
    }
    this._HttpService.putGroups(this.id, newData).subscribe(
      (resp) => {
        this.showMSG('Información actualizada', 'green', 'Extras Actualizados');
      },
      () => {
        this.showMSG('Error al Actualizar');
      }
    );
  }
  setActivar(index: number, estatus: boolean) {
    if (estatus) {
      this.extras[index].nombre.enable();
    } else {
      this.extras[index].nombre.disable();
    }
  }
  lleanrTabla(info: {}) {
    for (const key in info) {
      if (info.hasOwnProperty(key)) {
        const index = this.under.getindexExtra(key, this.extras);
        if (index >= 0) {
          this.extras[index].habilitar.setValue(true);
          this.extras[index].nombre.setValue(info[key]);
        }
      }
    }
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostar el mensaje del
  resultado de la accion realizada (agregar,
  actualizar, eliminar) ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  showMSG(mensaje: string, color?: string, titulo?: string, ) {
    let icono = 'error';
    if (!color && !titulo) {
      titulo = 'ERROR!';
    } else {
      switch (color) {
        case 'blue':
          icono = 'info';
          break;
        case 'green':
          icono = 'success';
          break;

        default:
          icono = 'error';
          break;
      }
    }
    this.msgs = [];
    this.msgs.push({ severity: icono, summary: titulo, detail: mensaje });
  }
}
