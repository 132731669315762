import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  imageUrls: (string | IImage)[] = [
    {
      url: '../../../assets/img/Carrusel_1.jpg',
      backgroundSize: 'cover'
    },
    // {
    //   url: '../../../assets/img/Carrusel_2.jpg',
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'top'
    // },
    {
      url: '../../../assets/img/Carrusel_3.jpg',
      backgroundSize: 'cover'
    },
    {
      url: '../../../assets/img/Carrusel_4.jpg',
      backgroundSize: 'cover'
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
