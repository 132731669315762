import { Component, OnInit } from '@angular/core';
import { TemaService } from '../index.services';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagerComponent implements OnInit {
  constructor(
    /*=====Servicios=====*/
    public _tema: TemaService
    /*=====Servicios=====*/
  ) {
  }


  ngOnInit() {
  }
  getUrlIMG(img: string): string {
    return 'url(' + img + ')';
  }



}
