import { UserAuth } from "./types/http";

export function getAuthUser(): UserAuth | null {
  const user = localStorage.getItem("usuario");
  if (user) {
    return JSON.parse(user);
  }

  return null;
}
