import { Component, OnInit } from '@angular/core';
import { TemaService } from '../../index.services';

@Component({
  selector: 'app-add-timbre',
  templateUrl: './add-timbre.component.html',
  styleUrls: ['./add-timbre.component.css']
})
export class AddTimbreComponent implements OnInit {

  constructor(public _tema: TemaService) { }

  ngOnInit() {
  }

}
