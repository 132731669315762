import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { Message, LazyLoadEvent, MenuItem } from "primeng/api";
import { CfdiInterface, CfdiModelo } from "../modelos/cfdi3.3";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { MatDrawer } from "@angular/material/sidenav";
import {
  HttpService,
  PermisosService,
  TemaService,
  LoadingService,
} from "../../../index.services";
/*momentJS*/
import * as moment from "moment";
import { MatDialog } from "@angular/material";
import { LoadingComponent } from "../../../modals/loading/loading.component";
import { MsgXmlPdfComponent } from "../../../modals/msg-xml-pdf/msg-xml-pdf.component";
import { ConfirmacionComponent } from "../../../modals/confirmacion/confirmacion.component";
import { DetalleComponent } from "../../../modals/detalle/detalle.component";
import { ReenvioCorroComponent } from "../../../modals/reenvio-corro/reenvio-corro.component";
import { Underscore } from "../../../classes/underscore";
import { CancellationTableComponent } from "src/app/modals/cancellation-table/cancellation-table.component";
import { Observable } from "rxjs";
import { setCfdiStatus } from "../cfdi-status";

@Component({
  selector: "app-advanced",
  templateUrl: "./advanced.component.html",
  styleUrls: ["./advanced.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AdvancedComponent implements OnInit {
  // clase pra uaso de underscore
  _under = new Underscore();
  /* etiquet anchor para descargar docs (para que fucione con firefox) */
  @ViewChild("anchorDownload") anchorDownload: ElementRef;
  /* etiquet anchor para ir al pag del Sat (para que fucione con firefox) */
  @ViewChild("anchorSat") anchorSat: ElementRef;
  /*control del sidebar de busqueda */
  @ViewChild("drawer1") drawer1: MatDrawer;
  msgs: Message[] = [];
  /*informacion de todos los comprobantes */
  comprobantes: CfdiInterface[] = new Array();
  onDownLoadExcel = false;
  /*formulario para realizar busquedas */
  formBuscarCFDI: FormGroup;
  maxDate = new Date();
  /*historial de la busqueda para hablilitar el boton de excel */
  histBuqueda: FormGroup;
  fitrosDeTable = null;
  paginaActual = 1;
  /*lista de los elementos selccionados */
  sel: CfdiInterface[] = [];
  verpag = false;
  /*bandera de error en la conexion */
  msgError: any = "";
  /*variable para gardar los parametros para genera el csv*/
  paramExcel: {
    busueda: {};
    filtro: {};
  };
  totalDatos = 0;
  limit = 250;

  /*form para el switch de mostrar columnas */
  cols = new FormGroup({
    columnToggler: new FormArray([]),
  });
  /*opciones para el Column Toggler */
  columnSelccion = new CfdiModelo().modelo;
  opTotalPag = [250, 500, 1000];
  totalDatosSel = this.opTotalPag[0];
  /*lista de opciones del menu contextual */
  opciones: MenuItem[] = [];
  /** primer display de tabla */
  primerDisplay = true;
  /** mensaje estándar para el proceso de cancelación de cfdis */
  cancelMSG =
    "El tiempo de espera para la cancelación por parte del SAT es de 1hr a 72hrs";
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*=====Servicios=====*/
    public _matDialog: MatDialog,
    public _httpService: HttpService,
    public _tema: TemaService,
    public _permisosService: PermisosService,
    private _loadingService: LoadingService /*=====Servicios=====*/
  ) {
    const IDate = new Date();
    IDate.setDate(1);
    /*=========================================*/
    /*controles del formulario formBuscarCFDI */
    this.formBuscarCFDI = new FormGroup({
      fechaInicial: new FormControl(IDate),
      fechaFinal: new FormControl(this.maxDate),
      folio: new FormControl(""),
      serie: new FormControl(""),
      rfcEmisor: new FormControl(""),
      rfcReceptor: new FormControl(""),
      estatus: new FormControl(""),
      paypalID: new FormControl(""),
    });
    /*controles y llenado del histrial de buqueda para habilitar el boton de descargar excel*/
    this.histBuqueda = new FormGroup({
      fechaInicial: new FormControl(
        this.formBuscarCFDI.controls["fechaInicial"].value
      ),
      fechaFinal: new FormControl(
        this.formBuscarCFDI.controls["fechaFinal"].value
      ),
      folio: new FormControl(this.formBuscarCFDI.controls["folio"].value),
      serie: new FormControl(this.formBuscarCFDI.controls["serie"].value),
      rfcEmisor: new FormControl(
        this.formBuscarCFDI.controls["rfcEmisor"].value
      ),
      rfcReceptor: new FormControl(
        this.formBuscarCFDI.controls["rfcReceptor"].value
      ),
      estatus: new FormControl(this.formBuscarCFDI.controls["estatus"].value),
      paypalID: new FormControl(this.formBuscarCFDI.controls["paypalID"].value),
    });
    /*controles y llenado del histrial de buqueda para habilitar el boton de descargar excel*/
    // this.histBuqueda = new FormGroup({
    //   fechaInicial: new FormControl(this.formBuscarCFDI.controls['fechaInicial'].value),
    //   fechaFinal: new FormControl(this.formBuscarCFDI.controls['fechaFinal'].value),
    //   folio: new FormControl(this.formBuscarCFDI.controls['folio'].value),
    //   serie: new FormControl(this.formBuscarCFDI.controls['serie'].value),
    //   rfcEmisor: new FormControl(this.formBuscarCFDI.controls['rfcEmisor'].value),
    //   rfcReceptor: new FormControl(this.formBuscarCFDI.controls['rfcReceptor'].value),
    //   estatus: new FormControl(this.formBuscarCFDI.controls['estatus'].value)
    // });
    for (let i = 0; i < this.columnSelccion.length; i++) {
      (<FormArray>this.cols.controls["columnToggler"]).push(
        new FormControl(this.columnSelccion[i].ver)
      );
    }
    /*++++++++++++++++++++++++++++++++++++++ seccion 1++++++++++++++++++++++++++++++++++++++ */
    /*opciones del menu contextual */
    if (_permisosService.verXmlPDF) {
      this.opciones.push({
        label: "Xml",
        icon: "icon-file-xml",
        styleClass: "blue",
        items: [
          {
            label: "Ver",
            icon: "icon-clipboard3",
            styleClass: "blue0",
            command: () => {
              if (this.sel[0] !== null) {
                this.getXml(this.sel, true);
              }
            },
          },
          {
            label: "Descargar",
            icon: "icon-file-download",
            styleClass: "blue1",
            command: () => {
              if (this.sel[0] !== null) {
                this.getXml(this.sel, false);
              }
            },
          },
        ],
      });
      this.opciones.push({
        label: "Pdf",
        icon: "icon-file-pdf",
        styleClass: "red",
        items: [
          {
            label: "Ver",
            icon: "icon-clipboard3",
            styleClass: "red0",
            command: () => {
              if (this.sel[0] !== null) {
                this.getPdf(this.sel, true);
              }
            },
          },
          {
            label: "Descargar",
            icon: "icon-file-download",
            styleClass: "red1",
            command: () => {
              if (this.sel[0] !== null) {
                this.getPdf(this.sel, false);
              }
            },
          },
        ],
      });
    }
    this.opciones.push({
      label: "Detalles",
      icon: "icon-file-eye",
      styleClass: "green",
      command: () => {
        if (this.sel[0] !== null) {
          this.openDetalle(this.sel[0].detalle);
        }
      },
    });
    if (this._permisosService.verEmali) {
      this.opciones.push({
        label: "Reenvio correo",
        icon: "icon-mail5",
        styleClass: "cyan",
        command: () => {
          if (this.sel[0] !== null) {
            if (this.sel.length > 1) {
              this.mostrarMSG("Favor de seleccionar sólo un comprobante");
            } else {
              if (this.sel[0].estatus === "Aprobado") {
                this.openReenvioCorro(this.sel[0].id);
              } else {
                this.mostrarMSG("Sólo se puede reenviar CFDI aprobados");
              }
            }
          }
        },
      });
    }

    if (_permisosService.verCancel) {
      this.opciones.push({
        label: "Cancelar",
        icon: "icon-stack-cancel",
        styleClass: "brown",
        command: () => this.onCancelar(),
      });
    }

    /*-------------------------------------- seccion 1-------------------------------------- */
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== ngOnInit ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  ngOnInit() {
    this._httpService.getGroups().subscribe(
      (resp) => {
        this.setNameExtras(resp);
      },
      (err) => {}
    );
    /*control para mostar o no las columnas segun la seleccionde mostrar columna */
    this.cols.controls["columnToggler"].valueChanges.subscribe((msg) => {
      for (let i = 0; i < msg.length; i++) {
        this.columnSelccion[i].ver = msg[i];
      }
      for (let i = 0; i < msg.length; i++) {
        if (msg[i]) {
          break;
        } else if (i === msg.length - 1) {
          (<FormArray>this.cols.controls["columnToggler"]).controls[1].setValue(
            true
          );
        }
      }
    });
    this.formBuscarCFDI.controls["estatus"].valueChanges.subscribe((msg) => {
      if (msg === undefined) {
        this.formBuscarCFDI.controls["estatus"].setValue("");
      }
    });
  }
  /* onCancelar XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * metodo para
   */
  onCancelar(): void {
    if (this.sel[0] !== null) {
      if (this.sel.length <= 20) {
        this._matDialog
          .open(CancellationTableComponent, {
            data: this.sel,
          })
          .afterClosed()
          .subscribe((resp) => {
            if (resp) {
              if (resp.requestCompleted) {
                let msg1: string;
                let msg2: string;
                if (
                  resp.response.not_found_uuids &&
                  resp.response.not_found_uuids.length
                ) {
                  msg1 = `Los siguientes CFDIs no pudieron ser procesados: ${resp.response.not_found_uuids.join()}`;
                  msg2 = this.cancelMSG;
                } else {
                  msg1 = this.cancelMSG;
                }
                this.mostrarMSG(msg1).subscribe(() => {
                  if (msg2) {
                    this.mostrarMSG(msg2);
                  }
                });
              } else {
                this.showMSG("Error al cancelar");
              }
            }
          });
      } else {
        this.mostrarMSG(
          "Sólo se pueden proceder con la cancelación de maximo 20 registros seleccionados"
        );
      }
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para controlar la bandera para habilitar/deshabilitar el boton de
   descargaar excel  ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  isDisbledExcel(): boolean {
    let resp = true;
    if (this.comprobantes.length > 0 && !this.onDownLoadExcel) {
      if (this.comprobantes[0] !== null) {
        Loop1: for (const key in this.formBuscarCFDI.controls) {
          if (this.formBuscarCFDI.controls.hasOwnProperty(key)) {
            if (
              this.formBuscarCFDI.controls[key].value !==
              this.histBuqueda.controls[key].value
            ) {
              resp = true;
              break Loop1;
            }
            resp = false;
          }
        }
        // resp = false;
      }
    }
    return resp;
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para limpiar los controladores de busqueda ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  limpiarForm() {
    this.formBuscarCFDI.controls["fechaInicial"].setValue("");
    this.formBuscarCFDI.controls["fechaFinal"].setValue(this.maxDate);
    this.formBuscarCFDI.controls["folio"].setValue("");
    this.formBuscarCFDI.controls["serie"].setValue("");
    this.formBuscarCFDI.controls["rfcEmisor"].setValue("");
    this.formBuscarCFDI.controls["rfcReceptor"].setValue("");
    this.formBuscarCFDI.controls["estatus"].setValue("");
    this.formBuscarCFDI.controls["paypalID"].setValue("");
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para buscar comprobantes ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  buscar(offset, paginaActual, fitrosTable?) {
    if (this.formBuscarCFDI.valid) {
      for (const key in this.formBuscarCFDI.controls) {
        if (this.formBuscarCFDI.controls.hasOwnProperty(key)) {
          this.histBuqueda.controls[key].setValue(
            this.formBuscarCFDI.controls[key].value
          );
        }
      }
      this.drawer1.close();
      if (fitrosTable) {
        this.fitrosDeTable = null;
      }

      this.paginaActual = paginaActual;

      this.comprobantes = [null];
      this.sel = [];
      this.verpag = false;

      this.msgError = "carga";
      // this.paramExcel['busueda'] = this.histBuqueda.value;
      // this.paramExcel['filtro'] = this.fitrosDeTable;
      this.paramExcel = {
        busueda: this.histBuqueda.value,
        filtro: this.fitrosDeTable,
      };
      this._httpService
        .getSearchTransactions(
          this.histBuqueda.value,
          offset,
          this.limit,
          this.fitrosDeTable
        )
        .subscribe(
          (resp) => {
            this.totalDatos = resp["total_count"];
            this.comprobantes = this.llenatTabla(resp["transactions"]);
            if (this.comprobantes.length > 0) {
              this.verpag = true;
            } else {
              this.comprobantes = [null];
            }
            this.msgError = "noRes";
            resp = null;
          },
          (err) => {
            if (err.error.message) {
              let temp: string = err.error.message;
              temp = temp.slice(0, 21);
              if (temp === "user cannot see CFDIs") {
                this.comprobantes = [null];
                this.msgError = "noRes";
              } else {
                this.comprobantes = [];
                this.msgError = "Error";
              }
            } else {
              this.comprobantes = [];
              this.msgError = "Error";
            }
          }
        );
    }
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar la tabla con la informacion del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  llenatTabla(data) {
    const cfdiArry: CfdiInterface[] = new Array();
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const cfdiDat: CfdiInterface = {
        id: null,
        estatus: "",
        serie: "",
        folio: null,
        version: "",
        rfcEmisor: "",
        nombreEmisor: "",
        rfcReceptor: "",
        nombreReceptor: "",
        folioReferencia: "",
        paypalID: "",
        // fechaEmision: '',
        fechaCreacion: "",
        total: null,
        moneda: "",
        uuid: "",
        detalle: "",
        extra1: "",
        extra2: "",
        extra3: "",
        extra4: "",
        extra5: "",
        extra6: "",
        extra7: "",
        extra8: "",
        extra9: "",
        extra10: "",
      };
      cfdiDat.id = element.id;
      cfdiDat.estatus = setCfdiStatus(element.stamp_status);

      // cfdiDat.fechaEmision = this.formatoFecha(element.timestamp, 'll');
      cfdiDat.fechaCreacion = this.formatoFecha(element.timestamp, "ll");
      // cfdiDat.rfcEmisor = element.sender;
      // cfdiDat.rfcEmisor = element.cfdi.sender_rfc;
      cfdiDat.rfcEmisor = "OPM150323DI1";
      cfdiDat.folioReferencia = "xdxdxddd";

      if (element.last_err) {
        cfdiDat.detalle = element.last_err;
      }
      if (element.cfdi) {
        const cfdi = element.cfdi;
        cfdiDat.moneda = cfdi.coin;
        // cfdiDat.fechaCreacion = this.formatoFecha(cfdi.emision_date, 'll');
        // cfdiDat.fechaEmision = this.formatoFecha(cfdi.emision_date, 'll');
        cfdiDat.folio = cfdi.folio;
        cfdiDat.nombreReceptor = cfdi.receptor_name;
        cfdiDat.rfcReceptor = cfdi.receptor_rfc;
        cfdiDat.nombreEmisor = cfdi.sender_name;
        // cfdiDat.rfcEmisor = cfdi.sender_rfc;
        cfdiDat.paypalID = cfdi.paypalID;
        cfdiDat.serie = cfdi.series;
        cfdiDat.total = cfdi.total;
        cfdiDat.uuid = cfdi.uuid;
        cfdiDat.version = cfdi.version;
        cfdiDat.extra1 = cfdi.extra1;
        cfdiDat.extra2 = cfdi.extra2;
        cfdiDat.extra3 = cfdi.extra3;
        cfdiDat.extra4 = cfdi.extra4;
        cfdiDat.extra5 = cfdi.extra5;
        cfdiDat.extra6 = cfdi.extra6;
        cfdiDat.extra7 = cfdi.extra7;
        cfdiDat.extra8 = cfdi.extra8;
        cfdiDat.extra9 = cfdi.extra9;
        cfdiDat.extra10 = cfdi.extra10;
        cfdiDat.folioReferencia = cfdi.folio_referencia;
      }
      cfdiArry.push(cfdiDat);
    }
    return cfdiArry;
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para dar formatato a las fechas ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  formatoFecha(fecha: Date, formato: string) {
    return moment(fecha).locale("es").format(formato);
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*===metodo para solicitar elexcel del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getExcel() {
    this.onDownLoadExcel = true;
    this.opneModalLoading(-1);
    this._httpService
      .getCSV(this.paramExcel.busueda, this.paramExcel.filtro)
      .subscribe(
        (resp) => {
          this._loadingService.eveCerrar.next(true);
          const name = this.formatoFecha(new Date(), "lll");
          this.getFile(resp, false, "csv", name);
          this.onDownLoadExcel = false;
        },
        () => {
          this._loadingService.eveCerrar.next(false);
          this.showMSG("Error al obtener el archivo");
          this.onDownLoadExcel = false;
        }
      );
  }
  opneModalLoading(totalFiles: number, avgSize?: number) {
    let total = -1;
    if (avgSize) {
      total = totalFiles * avgSize;
    }
    const modal = this._matDialog.open(LoadingComponent, {
      panelClass: "loading-modal",
      disableClose: true,
      // backdropClass: 'cortina-loading-modal',
      data: { total: total },
    });
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para descargar/ver el archivo que manda el back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getFile(
    info,
    open: boolean,
    tipo: string,
    nombre: string,
    addExtencion: boolean = false
  ) {
    const newBlob = new Blob([info], { type: "application/" + tipo });
    const data = window.URL.createObjectURL(newBlob);
    if (open) {
      window.open(data);
    } else {
      this.anchorDownload.nativeElement.href = data;
      if (addExtencion) {
        this.anchorDownload.nativeElement.download = nombre;
      } else {
        this.anchorDownload.nativeElement.download = nombre + "." + tipo;
      }
      this.anchorDownload.nativeElement.click();
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*===metodo para mostrar el mensaje de informacion (msg superior derecha) ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  showMSG(mensaje: string, color?: string, titulo?: string) {
    let icono = "error";
    if (!color && !titulo) {
      titulo = "ERROR!";
    } else {
      switch (color) {
        case "blue":
          icono = "info";
          break;
        case "green":
          icono = "success";
          break;

        default:
          icono = "error";
          break;
      }
    }
    this.msgs = [];
    this.msgs.push({ severity: icono, summary: titulo, detail: mensaje });
  }
  getListPDF() {
    const downloadPDF = () => {
      this.onDownLoadExcel = true;
      this.opneModalLoading(this.totalDatos, 55000);
      this._httpService
        .getListPDF(this.paramExcel.busueda, this.paramExcel.filtro)
        .subscribe(
          (resp) => {
            this._loadingService.eveCerrar.next(true);
            const name = this.formatoFecha(new Date(), "lll");
            this.getFile(resp, false, "zip", name);
            this.onDownLoadExcel = false;
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
            this.onDownLoadExcel = false;
          }
        );
    };
    if (this.sel.length) {
      this.getPdf(this.sel, false);
    } else if (this.totalDatos > 1000) {
      const modal = this._matDialog.open(ConfirmacionComponent, {
        data: {
          msg:
            "Esta apunto de descargar " +
            this.totalDatos +
            " archivos, ¿desea continuar?",
        },
      });
      modal.afterClosed().subscribe((msg) => {
        if (msg === "Aceptar") {
          downloadPDF();
        }
      });
    } else {
      downloadPDF();
    }
  }
  getListXML() {
    const downloadXML = () => {
      this.onDownLoadExcel = true;
      this.opneModalLoading(this.totalDatos, 6200);
      this._httpService
        .getListXML(this.paramExcel.busueda, this.paramExcel.filtro)
        .subscribe(
          (resp) => {
            this._loadingService.eveCerrar.next(true);
            const name = this.formatoFecha(new Date(), "lll");
            this.getFile(resp, false, "zip", name);
            this.onDownLoadExcel = false;
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
            this.onDownLoadExcel = false;
          }
        );
    };
    if (this.sel.length) {
      this.getXml(this.sel, false);
    } else if (this.totalDatos > 1000) {
      const modal = this._matDialog.open(ConfirmacionComponent, {
        data: {
          msg:
            "Esta apunto de descargar " +
            this.totalDatos +
            " archivos, ¿desea continuar?",
        },
      });
      modal.afterClosed().subscribe((msg) => {
        if (msg === "Aceptar") {
          downloadXML();
        }
      });
    } else {
      downloadXML();
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para pedir una nueva pagina (detondao por el componente
   'app-pages') ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  onChangePage(nextPage) {
    this.totalDatosSel = nextPage.selTotalPag;
    this.limit = nextPage.limit;
    this.buscar(nextPage.offset, nextPage.numPagActual);
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para atrapar los eventos que surgen la tabla (filtros y ordenar)===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  loadLazy(event: LazyLoadEvent) {
    if (this.primerDisplay) {
      this.primerDisplay = false;
    } else {
      const temp = {};
      if (Object.keys(event.filters).length > 0) {
        for (const key in event.filters) {
          if (event.filters.hasOwnProperty(key)) {
            const element = event.filters[key];
            if (key === "nombreEmisor") {
              temp["nombre-Emisor"] = element.value;
            } else if (key === "nombreReceptor") {
              temp["nombre-Receptor"] = element.value;
            } else if (key === "rfcReceptor") {
              temp["receptorRFC"] = element.value;
            } else {
              temp[key] = element.value;
            }
          }
        }
      }
      if (event.sortField !== undefined) {
        temp["order-Col"] = event.sortField;
        if (event.sortOrder === 1) {
          temp["order-Dir"] = "asc";
        } else {
          temp["order-Dir"] = "desc";
        }
      }

      if (Object.keys(temp).length > 0) {
        this.fitrosDeTable = temp;
        // this.buscar(0, 1);
      } else {
        this.fitrosDeTable = null;
      }
      this.buscar(0, 1);
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar eL xml del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getXml(seleccion: CfdiInterface[], open: boolean) {
    const FileSize = 6770;
    const avgFileSize = 56000;
    /*ABRIR solo un XML */
    if (seleccion.length === 1 && open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(-1);
        this._httpService.getXML(seleccion[0].id).subscribe(
          (resp: { data: Blob; extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(
              resp.data,
              open,
              "xml",
              seleccion[0].rfcReceptor,
              true
            );
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
          }
        );
      } else {
        this.mostrarMSG("El documento XML de este CFDI no está disponible");
      }
      /*ABRIR multiples xml */
    } else if (seleccion.length > 1 && open) {
      for (let index = 0; index < seleccion.length; index++) {
        const element = seleccion[index];
        if (element.uuid.length > 0) {
          this.opneModalLoading(-1);
          this._httpService.getXML(element.id).subscribe(
            (resp: { data: Blob; extension: string }) => {
              this._loadingService.eveCerrar.next(true);
              this.getFile(resp.data, open, "xml", element.rfcReceptor);
            },
            () => {
              this._loadingService.eveCerrar.next(false);
              this.showMSG("Error al obtener el archivo");
            }
          );
        }
      }
      /*DESCARGAR solo un xml */
    } else if (seleccion.length === 1 && !open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(1, FileSize);
        this._httpService.getXML(seleccion[0].id).subscribe(
          (resp: { data: Blob; extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, "xml", resp.extension, true);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
          }
        );
      } else {
        this.mostrarMSG("El documento XML de este CFDI no está disponible");
      }
      /*DESCARGAR multiples xml */
    } else if (seleccion.length > 1 && !open) {
      const idList = [];
      for (let index = 0; index < seleccion.length; index++) {
        idList.push(seleccion[index].id);
      }
      this.opneModalLoading(idList.length, avgFileSize);
      this._httpService.postMasivXML(idList).subscribe(
        (resp) => {
          this._loadingService.eveCerrar.next(true);
          const name = this.formatoFecha(new Date(), "lll");
          this.getFile(resp, false, "zip", name);
        },
        () => {
          this._loadingService.eveCerrar.next(false);
          this.showMSG("Error al obtener el archivo");
        }
      );
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar EL PDF del back ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  getPdf(seleccion: CfdiInterface[], open: boolean) {
    const FileSize = 5948;
    const avgFileSize = 330000;
    /*ABIR solo un pdf */
    if (seleccion.length === 1 && open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(-1);
        this._httpService.getPDF(seleccion[0].id).subscribe(
          (resp: { data: Blob; extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, "pdf", seleccion[0].rfcReceptor);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
          }
        );
      } else {
        this.mostrarMSG("El documento PDF de este CFDI no está disponible");
      }
      /*ABRIR multiples pdf */
    } else if (seleccion.length > 1 && open) {
      for (let index = 0; index < seleccion.length; index++) {
        const element = seleccion[index];
        if (element.uuid.length > 0) {
          this.opneModalLoading(-1);
          this._httpService.getPDF(element.id).subscribe(
            (resp: { data: Blob; extension: string }) => {
              this._loadingService.eveCerrar.next(true);
              this.getFile(resp.data, open, "pdf", element.rfcReceptor);
            },
            () => {
              this._loadingService.eveCerrar.next(false);
              this.showMSG("Error al obtener el archivo");
            }
          );
        }
      }
      /*DESCARGAR solo un pdf */
    } else if (seleccion.length === 1 && !open) {
      if (seleccion[0].uuid.length > 0) {
        this.opneModalLoading(1, FileSize);
        this._httpService.getPDF(seleccion[0].id).subscribe(
          (resp: { data: Blob; extension: string }) => {
            this._loadingService.eveCerrar.next(true);
            this.getFile(resp.data, open, "pdf", resp.extension, true);
          },
          () => {
            this._loadingService.eveCerrar.next(false);
            this.showMSG("Error al obtener el archivo");
          }
        );
      } else {
        this.mostrarMSG("El documento PDF de este CFDI no está disponible");
      }
      /*DESCARGAR multiples pdf */
    } else if (seleccion.length > 1 && !open) {
      const idList = [];
      for (let index = 0; index < seleccion.length; index++) {
        idList.push(seleccion[index].id);
      }
      this.opneModalLoading(idList.length, avgFileSize);
      this._httpService.postMasivPDF(idList).subscribe(
        (resp) => {
          this._loadingService.eveCerrar.next(true);
          const name = this.formatoFecha(new Date(), "lll");
          this.getFile(resp, false, "zip", name);
        },
        () => {
          this._loadingService.eveCerrar.next(false);
          this.showMSG("Error al obtener el archivo");
        }
      );
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostrar una mensaje modal===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  mostrarMSG(msg: string): Observable<any> {
    return this._matDialog
      .open(MsgXmlPdfComponent, {
        minWidth: "250px",
        data: { msg: msg },
      })
      .afterClosed();
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostar el modal de detalle ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openDetalle(detalle: string) {
    const modal = this._matDialog.open(DetalleComponent, {
      minWidth: "200px",
      data: { detalle: detalle },
    });
  }
  reTimbrar(sel: CfdiInterface[]) {
    const cfdiValid: number[] = [];

    for (let index = 0; index < sel.length; index++) {
      const element = sel[index];
      if (element.estatus !== "Aprobado") {
        cfdiValid.push(element.id);
      }
    }

    if (cfdiValid.length > 0) {
      this._httpService.postReTimbre(cfdiValid[0]).subscribe(
        () => {
          this.showMSG(
            "Se comenzó el proceso de re-timbrado del CFDI",
            "blue",
            "CFDI Re-Timbrado"
          );
        },
        () => {
          this.showMSG("Error al re-timbrar el CFDI");
        }
      );
    } else {
      this.mostrarMSG("Los CFDI Aprobados no se pueden re-timbrar");
    }
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostar el modal de envio de correo ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openReenvioCorro(idCFDI: number) {
    const modal = this._matDialog.open(ReenvioCorroComponent, {
      width: "600px",
      data: { id: idCFDI },
    });
    modal.afterClosed().subscribe((msg) => {
      if (msg === 0) {
        this.showMSG(
          "Correo(s) enviado(s) de forma correcta",
          "green",
          "Correo Enviado"
        );
      } else if (msg === 1) {
        this.showMSG("Error al enviar");
      }
    });
  }
  get FC_columnToggler() {
    // cols.controls['columnToggler'].controls
    return (<FormArray>this.cols.controls["columnToggler"]).controls;
    // return (<FormArray>this.formStep2.controls['empresaEmi']).controls;
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== abrir link del sat ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  openLinkSat(id: number) {
    this._httpService.getSat(id).subscribe(
      (resp) => {
        this.anchorSat.nativeElement.href = resp["url"];
        this.anchorSat.nativeElement.click();
      },
      () => {
        this.showMSG("Error al abrir la dirección");
      }
    );
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para llenar los nombres de los campos extra ===*/
  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  setNameExtras(nomExtras: {}) {
    for (const key in nomExtras) {
      if (nomExtras.hasOwnProperty(key)) {
        const nombre = nomExtras[key];
        switch (key) {
          case "extra1_name":
            this.columnSelccion[13].header = nombre;
            this.columnSelccion[13].setOption = true;
            break;
          case "extra2_name":
            this.columnSelccion[14].header = nombre;
            this.columnSelccion[14].setOption = true;
            break;
          case "extra3_name":
            this.columnSelccion[15].header = nombre;
            this.columnSelccion[15].setOption = true;
            break;
          case "extra4_name":
            this.columnSelccion[16].header = nombre;
            this.columnSelccion[16].setOption = true;
            break;
          case "extra5_name":
            this.columnSelccion[17].header = nombre;
            this.columnSelccion[17].setOption = true;
            break;
          case "extra6_name":
            this.columnSelccion[18].header = nombre;
            this.columnSelccion[18].setOption = true;
            break;
          case "extra7_name":
            this.columnSelccion[19].header = nombre;
            this.columnSelccion[19].setOption = true;
            break;
          case "extra8_name":
            this.columnSelccion[20].header = nombre;
            this.columnSelccion[20].setOption = true;
            break;
          case "extra9_name":
            this.columnSelccion[21].header = nombre;
            this.columnSelccion[21].setOption = true;
            break;
          case "extra10_name":
            this.columnSelccion[22].header = nombre;
            this.columnSelccion[22].setOption = true;
            break;
        }
      }
    }
  }
}
