export const TaxRegimeCatalog: { value: string; description: string }[] = [
  { value: "601", description: "General de Ley Personas Morales" },
  { value: "603", description: "Personas Morales con Fines no Lucrativos" },
  {
    value: "605",
    description: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  { value: "606", description: "Arrendamiento" },
  {
    value: "607",
    description: "Régimen de Enajenación o Adquisición de Bienes",
  },
  { value: "608", description: "Demás ingresos" },
  {
    value: "610",
    description:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    value: "611",
    description: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    value: "612",
    description:
      "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { value: "614", description: "Ingresos por intereses" },
  {
    value: "615",
    description: "Régimen de los ingresos por obtención de premios",
  },
  { value: "616", description: "Sin obligaciones fiscales" },
  {
    value: "620",
    description:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  { value: "621", description: "Incorporación Fiscal" },
  {
    value: "622",
    description: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  { value: "623", description: "Opcional para Grupos de Sociedades" },
  { value: "624", description: "Coordinados" },
  {
    value: "625",
    description:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  { value: "626", description: "Régimen Simplificado de Confianza" },
];
