import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { HttpService } from "src/app/index.services";
import { getAuthUser } from "src/app/services/auth-token";

@Component({
  selector: "app-business-name-notification",
  templateUrl: "./business-name-notification.component.html",
  styleUrls: ["./business-name-notification.component.css"],
})
export class BusinessNameNotificationComponent implements OnInit {
  /** saved state check flag */
  isLoading = false;
  ctrlBusinessName = new FormControl("", Validators.required);
  ctrlIRead = false;
  constructor(
    public dialogRef: MatDialogRef<BusinessNameNotificationComponent>,
    private httpService: HttpService
  ) {}

  ngOnInit() {}
  /* onGuardar XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
  /**
   * method to save company name
   */
  onGuardar(): void {
    this.ctrlBusinessName.markAsTouched();
    if (this.ctrlBusinessName.valid) {
      this.isLoading = true;
      const data = {
        business_name: (this.ctrlBusinessName.value as string).toUpperCase(),
      };
      this.httpService.patchMyUser(data).subscribe(
        () => {
          this.dialogRef.close("ok");
        },
        () => {
          this.dialogRef.close("error");
        }
      );
    }
  }
}
