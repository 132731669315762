import { Injectable } from '@angular/core';
import { LoginUser } from '../interface/interface';
import { TemaService } from './tema.service';
import { AuthGuardService } from './auth-guard.service';
import { Router } from '@angular/router';
/*constantes de las direcciones de los end point */
import { epLogin } from './endpoint';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PermisosService } from './permisos.service';



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  // loged = false;


  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*+++++servicios+++++*/
    public _tema: TemaService,
    // private _guard: AuthGuardService,
    private _router: Router,
    private _http: HttpClient,
    private _permisosService: PermisosService
    /*-----servicios-----*/
  ) { }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo pra dar login ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  login(userInfo: LoginUser) {

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + (btoa(userInfo.user + ':' + userInfo.pass)),
      'Content-Type': 'application/json'
    });
    return this._http.post(epLogin.login, null, { headers: headers });
  }



  setLoged() {
    this._tema.tema = '';
    this._tema.img = '';
    this._tema.saveTema();
    // this._guard.activo = true;
    // this.loged = true;
  }
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo par dar logout ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  logout() {
    this._tema.tema = '';
    this._tema.img = '';
    this._router.navigate(['']);
    // localStorage.clear();
    localStorage.removeItem('usuario');
    localStorage.removeItem('tema');
    this._permisosService.limpiarPermisis();
  }
}
