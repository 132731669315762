import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { Message } from 'primeng/api';

import { MatDialog } from '@angular/material';
import { EmpresaComponent } from '../../modals/empresa/empresa.component';
import { LogotiposComponent } from '../../modals/logotipos/logotipos.component';
import { IdentidadComponent } from '../../modals/identidad/identidad.component';
import { HttpService } from '../../services/http.service';
import { EditarEmpresaComponent } from 'src/app/modals/editar-empresa/editar-empresa.component';
import { ConfirmacionComponent } from '../../modals/confirmacion/confirmacion.component';

interface Empresa {
  name: string;
  rfc: string;
}

@Component({
  selector: 'app-empresas-emisoras',
  templateUrl: './empresas-emisoras.component.html',
  styleUrls: ['./empresas-emisoras.component.css']
})



export class EmpresasEmisorasComponent implements OnInit {
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== Variables ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  msgs: Message[] = [];

  /*bandera de error en la conexion */
  msgError = 'carga';


  /*columnas de la tabla */
  columnas = [{
    field: 'name',
    header: 'Nombre'
  }, {
    field: 'rfc',
    header: 'RFC'
  }];

  opciones: MenuItem[];
  /*lista de la informacion de las empresas */
  empresas: Empresa[] = new Array();
  /*empresa sleccionada */
  selccion: Empresa;

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== constructor ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  constructor(
    /*----servicios ----*/
    public _matDialog: MatDialog,
    public _httpService: HttpService
    /*----servicios ----*/
  ) {

    _httpService.getCompanies().subscribe(
      (resp: { companies: Empresa[] }) => {
        this.empresas = resp.companies;
        this.msgError = 'sinReg';
      },
      () => {
        this.msgError = 'Error';

      }
    );


    /*lista de acciones para el menu contextual*/
    this.opciones = [
      {
        label: 'Editar', icon: 'icon-pencil', styleClass: 'green',
        command: (eve) => {
          this.openEditEmpresa(this.selccion);
        }
      },
      {
        label: 'Logotipos', icon: ' icon-file-picture', styleClass: 'purple',
        command: (eve) => {
          this.openLogotipos();
        }
      },
      {
        label: 'Identidad', icon: 'fa fa-desktop', styleClass: 'blue',
        command: (eve) => {
          this.openIdentidad();
        }
      },
      {
        label: 'Eliminar', icon: ' icon-cross2', styleClass: 'red',
        command: (eve) => {
          this.eliminar(this.selccion.rfc);
        }
      }
    ];
  }

  ngOnInit() {
  }

  openEmpresa() {
    const modalEmp = this._matDialog.open(EmpresaComponent);
    modalEmp.afterClosed().subscribe(msg => {
      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Empresa creada de forma correcta', 'green', 'Empresa Creada');
      } else if (msg === 'error') {
        this.showMSG('Error al crear la empresa');
      }
    });
  }
  openEditEmpresa(infoEmp: Empresa) {
    const modalEmp = this._matDialog.open(EditarEmpresaComponent, {
      data: infoEmp
    }
    );
    modalEmp.afterClosed().subscribe(msg => {
      if (msg === 'ok') {
        this.reCargarTabla();
        this.showMSG('Empresa modificada de forma correcta', 'green', 'Empresa Modificada');
      } else if (msg === 'error') {
        this.showMSG('Error al modificar la empresa');
      }
    });
  }
  openLogotipos() {
    const modalEmp = this._matDialog.open(LogotiposComponent);
  }
  eliminar(rfc: string) {
    const modal = this._matDialog.open(ConfirmacionComponent,
      {
        data: { msg: 'Desea eliminar la empresa?' }
      }
    );

    modal.afterClosed().subscribe(msg => {
      if (msg === 'Aceptar') {

        this._httpService.deleteCompany(rfc).subscribe(
          () => {
            this.reCargarTabla();
            this.showMSG('Empresa eliminada de forma correcta', 'blue', 'Empresa Eliminada');
          },
          () => {
            this.showMSG('Error al eliminar la empresa');
          });
      }
    });

  }

  openIdentidad() {
    const modalEmp = this._matDialog.open(IdentidadComponent);
  }


  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para solicitar actualizar la
  informacion de la tabla ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  reCargarTabla() {
    this.empresas = new Array();
    this.msgError = 'carga';
    this._httpService.getCompanies().subscribe(
      (resp: { companies: Empresa[] }) => {
        this.empresas = resp.companies;
        this.msgError = 'sinReg';
      },
      () => {
        this.msgError = 'Error';
      }
    );
  }

  /*+++++++++++++++++++++++++++++++++++++++++++*/
  /*=== metodo para mostar el mensaje del
  resultado de la accion realizada (agregar,
  actualizar, eliminar) ===*/
  /*+++++++++++++++++++++++++++++++++++++++++++*/
  showMSG(mensaje: string, color?: string, titulo?: string, ) {
    let icono = 'error';
    if (!color && !titulo) {
      titulo = 'ERROR!';
    } else {
      switch (color) {
        case 'blue':
          icono = 'info';
          break;
        case 'green':
          icono = 'success';
          break;

        default:
          icono = 'error';
          break;
      }
    }
    this.msgs = [];
    this.msgs.push({ severity: icono, summary: titulo, detail: mensaje });
  }

}
