import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // evento para mostrar cerrar el modal;
  eveCerrar: Subject<boolean> = new Subject();
  // evento para registar un cambio en el rpogreso de la descrga
  eveDescaraga: Subject<number> = new Subject();

  constructor() { }
}
